import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, filter, map, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';

import * as commonActions from './common.actions';
import {CommonService} from 'src/app/core/services/common.service';
import {ApplicationState} from 'src/app/app.state';
import {statuses} from './common.state';

@Injectable()
export class CommonEffects {
  @Effect()
  public loadStatusesEffect = this.actions.pipe(
    ofType(commonActions.CommonActions.LOAD_STATUSES),
    withLatestFrom(this.store.pipe(select(statuses))),
    filter(([_, statuses]) => {
      if (statuses) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.commonService.fetchStatuses().pipe(
        map(response => (
          new commonActions.LoadStatusesSuccess(response)
        )),
        catchError((error) => of(
          new commonActions.LoadStatusesFailed(error)
        ))
      )
    )
  );

  constructor(
    public actions: Actions,
    public store: Store<ApplicationState>,
    public commonService: CommonService
  ) {
  }

}

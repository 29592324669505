import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as themeActions from './theme.actions';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ApplicationState } from 'src/app/app.state';
import { themes } from './theme.state';

@Injectable()
export class ThemeEffects {
  @Effect()
  public loadThemesEffect = this.actions.pipe(
    ofType(themeActions.ThemeActions.LOAD_THEMES),
    withLatestFrom(this.store.pipe(select(themes))),
    filter(([_, themes]) => {
      if (themes) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.themeService.fetchThemes().pipe(
        map(response => (
          new themeActions.LoadThemesSuccess(response)
        )),
        catchError((error) => of(
          new themeActions.LoadThemesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createThemeEffect = this.actions.pipe(
    ofType(themeActions.ThemeActions.CREATE_THEME),
    exhaustMap((action: themeActions.CreateTheme) =>
      this.themeService.createTheme(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new themeActions.CreateThemeSuccess(response);
          }),
          catchError(error => of(new themeActions.CreateThemeFailed(error)))
        )
    )
  );
  @Effect()
  public updateThemeByIdEffect = this.actions.pipe(
    ofType(themeActions.ThemeActions.UPDATE_THEME),
    exhaustMap((action: themeActions.UpdateTheme) =>
      this.themeService.updateTheme(action.payload)
        .pipe(
          map(() => new themeActions.UpdateThemeSuccess(action.payload)),
          catchError(error => of(new themeActions.UpdateThemeFailed(error)))
        )
    )
  );
  @Effect()
  public removeThemeByIdEffect = this.actions.pipe(
    ofType(themeActions.ThemeActions.REMOVE_THEME),
    switchMap((action: themeActions.RemoveTheme) =>
      this.themeService.removeTheme(action.payload)
        .pipe(
          map(() => new themeActions.RemoveThemeSuccess(action.payload)),
          catchError(error => of(new themeActions.RemoveThemeFailed(error)))
        )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public themeService: ThemeService
  ) {
  }

}

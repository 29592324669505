import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (JSON.parse(sessionStorage.getItem('company_id'))) {
      request = request.clone({
        setHeaders: {
          company_id: JSON.parse(sessionStorage.getItem('company_id'))
        }
      });
    }
    return next.handle(request);
  }
}

import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import * as userStore from './store/user';
import * as commonStore from './store/common';
import * as enquiryStore from './store/enquiry';
import * as subscriptionStore from './store/subscription';
import * as companyStore from './store/company';
import * as programStore from './store/program';
import * as geographicCodeStore from './store/geographic.code';
import * as themeStore from './store/theme';
import * as directorStore from './store/director';
import * as tourStore from './store/tour';

@Injectable()
export class AppEffects {
  constructor(private actions: Actions) { }
}

export const effects: Array<any> = [
  userStore.UserEffects,
  commonStore.CommonEffects,
  enquiryStore.EnquiryEffects,
  subscriptionStore.SubscriptionEffects,
  companyStore.CompanyEffects,
  programStore.ProgramEffects,
  geographicCodeStore.GeographicCodeEffects,
  themeStore.ThemeEffects,
  directorStore.DirectorEffects,
  tourStore.TourEffects,
];

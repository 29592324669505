import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { IEnquiry } from 'src/app/core/models/Enquiry';

export interface EnquiryState {
  isProcessing: boolean;
  enquiries: [IEnquiry];
  message: any;
}

export const initialState: EnquiryState = {
  isProcessing: false,
  enquiries: null,
  message: null
};

export const getApplicationState = (state: ApplicationState) => state;

// exports enquiryState from ApplicationState
export const enquiryState = createSelector(getApplicationState, (state: ApplicationState) => state.enquiryState);
// exports enquiries from ApplicationState
export const enquiries = createSelector(getApplicationState, (state: ApplicationState) => state.enquiryState.enquiries);

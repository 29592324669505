import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {ApplicationState} from 'src/app/app.state';
import {initialState, userState, Userstate} from 'src/app/store/user/user.state';
import {Logout, SetCompany} from 'src/app/store/user';

@Component({
  selector: 'app-personalize-company-form',
  templateUrl: './personalize-company-form.component.html',
  styleUrls: ['./personalize-company-form.component.css']
})
export class PersonalizeCompanyFormComponent implements OnInit {
  companyForm: FormGroup;
  authenticationState: Observable<Userstate>;
  companies: any;
  company_id: number;

  @ViewChild('selectCompany') selectCompany: ElementRef;

  constructor(public store: Store<ApplicationState>) {
  }

  // Initialise company from
  initialiseCompanyForm = () => {
    this.companyForm = new FormGroup({
      company_id: new FormControl(
        this.company_id
          ? this.company_id
          : null,
        [Validators.required]
      )
    });
    setTimeout(() => {
      if (this.selectCompany) {
        this.selectCompany.nativeElement.focus();
      }
    }, 0);
  };

  onCancel = (): void => {
    sessionStorage.clear();
    localStorage.clear();
    this.store.dispatch(new Logout(initialState));
  };

  setCompany = (): void => {
    // Dispatching event for personalizing company
    this.store.dispatch(new SetCompany(
      this.companies.find(
        (company: any) => company.company_id === this.companyForm.value.company_id
      )
    ));
  };

  ngOnInit() {
    this.authenticationState = this.store.pipe(select(userState));
    this.authenticationState.subscribe((currentUserState: Userstate) => {
      this.companies = (currentUserState.companies as any);
      // If companies length is 1, company form will be initialised with that company_id
      if (currentUserState.companies && currentUserState.companies.length === 1) {
        this.company_id = (currentUserState.companies[0] as any).company_id;
        this.initialiseCompanyForm();
      }
    });
    this.initialiseCompanyForm();
  }

}

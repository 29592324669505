import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as directorActions from './director.actions';
import { DirectorService } from 'src/app/core/services/director.service';
import { ApplicationState } from 'src/app/app.state';
import { directors } from './director.state';

@Injectable()
export class DirectorEffects {
  @Effect()
  public loadDirectorsEffect = this.actions.pipe(
    ofType(directorActions.DirectorActions.LOAD_DIRECTORS),
    withLatestFrom(this.store.pipe(select(directors))),
    filter(([_, directors]) => {
      if (directors) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.directorService.fetchDirectors().pipe(
        map(response => (
          new directorActions.LoadDirectorsSuccess(response)
        )),
        catchError((error) => of(
          new directorActions.LoadDirectorsFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createDirectorEffect = this.actions.pipe(
    ofType(directorActions.DirectorActions.CREATE_DIRECTOR),
    exhaustMap((action: directorActions.CreateDirector) =>
      this.directorService.createDirector(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new directorActions.CreateDirectorSuccess(response);
          }),
          catchError(error => of(new directorActions.CreateDirectorFailed(error)))
        )
    )
  );
  @Effect()
  public updateDirectorByIdEffect = this.actions.pipe(
    ofType(directorActions.DirectorActions.UPDATE_DIRECTOR),
    exhaustMap((action: directorActions.UpdateDirector) =>
      this.directorService.updateDirector(action.payload)
        .pipe(
          map(() => new directorActions.UpdateDirectorSuccess(action.payload)),
          catchError(error => of(new directorActions.UpdateDirectorFailed(error)))
        )
    )
  );
  @Effect()
  public removeDirectorByIdEffect = this.actions.pipe(
    ofType(directorActions.DirectorActions.REMOVE_DIRECTOR),
    switchMap((action: directorActions.RemoveDirector) =>
      this.directorService.removeDirector(action.payload)
        .pipe(
          map(() => new directorActions.RemoveDirectorSuccess(action.payload)),
          catchError(error => of(new directorActions.RemoveDirectorFailed(error)))
        )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public directorService: DirectorService
  ) {
  }

}

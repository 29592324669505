import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.css']
})
export class FilterButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() btnText: string;

  @Output() applyFilter = new EventEmitter();

  constructor() {
  }

  applyFilterEvent = () => {
    this.applyFilter.emit();
  };

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as geographicCodeActions from './geographic.code.actions';
import { GeographicCodeService } from 'src/app/core/services/geographic.code.service';
import { ApplicationState } from 'src/app/app.state';
import { geographicCodes } from './geographic.code.state';

@Injectable()
export class GeographicCodeEffects {
  @Effect()
  public loadGeographicCodesEffect = this.actions.pipe(
    ofType(geographicCodeActions.GeographicCodeActions.LOAD_GEOGRAPHICCODES),
    withLatestFrom(this.store.pipe(select(geographicCodes))),
    filter(([_, geographicCodes]) => {
      if (geographicCodes) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.geographicCodeService.fetchGeographicCodes().pipe(
        map(response => (
          new geographicCodeActions.LoadGeographicCodesSuccess(response)
        )),
        catchError((error) => of(
          new geographicCodeActions.LoadGeographicCodesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createGeographicCodeEffect = this.actions.pipe(
    ofType(geographicCodeActions.GeographicCodeActions.CREATE_GEOGRAPHICCODE),
    exhaustMap((action: geographicCodeActions.CreateGeographicCode) =>
      this.geographicCodeService.createGeographicCode(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new geographicCodeActions.CreateGeographicCodeSuccess(response);
          }),
          catchError(error => of(new geographicCodeActions.CreateGeographicCodeFailed(error)))
        )
    )
  );
  @Effect()
  public updateGeographicCodeByIdEffect = this.actions.pipe(
    ofType(geographicCodeActions.GeographicCodeActions.UPDATE_GEOGRAPHICCODE),
    exhaustMap((action: geographicCodeActions.UpdateGeographicCode) =>
      this.geographicCodeService.updateGeographicCode(action.payload)
        .pipe(
          map(() => new geographicCodeActions.UpdateGeographicCodeSuccess(action.payload)),
          catchError(error => of(new geographicCodeActions.UpdateGeographicCodeFailed(error)))
        )
    )
  );
  @Effect()
  public removeGeographicCodeByIdEffect = this.actions.pipe(
    ofType(geographicCodeActions.GeographicCodeActions.REMOVE_GEOGRAPHICCODE),
    switchMap((action: geographicCodeActions.RemoveGeographicCode) =>
      this.geographicCodeService.removeGeographicCode(action.payload)
        .pipe(
          map(() => new geographicCodeActions.RemoveGeographicCodeSuccess(action.payload)),
          catchError(error => of(new geographicCodeActions.RemoveGeographicCodeFailed(error)))
        )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public geographicCodeService: GeographicCodeService
  ) {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';

// Modules
import { AppContainerRoutingModule } from './app-container-routing.module';
import { ComponentsModule } from '../components/components.module';
import { ElementsModule } from 'src/app/modules/elements/elements.module';

// Components
import { NavigationComponent } from './navigation/navigation.component';
import { ContainerHeaderComponent } from './container-header/container-header.component';
import { ListComponent } from './list/list.component';
import { ListRowComponent } from './list-row/list-row.component';
import { SettingsListComponent } from './settings-list/settings-list.component';
import { SettingsListRowComponent } from './settings-list-row/settings-list-row.component';
import { ContainerIconComponent } from './container-icon/container-icon.component';
import { MessageSectionComponent } from './message-section/message-section.component';
import { MessageInfoSectionComponent } from './message-info-section/message-info-section.component';

@NgModule({
  declarations: [
    NavigationComponent,
    ContainerHeaderComponent,
    ListComponent,
    ListRowComponent,
    SettingsListComponent,
    SettingsListRowComponent,
    ContainerIconComponent,
    MessageSectionComponent,
    MessageInfoSectionComponent
  ],
  imports: [
    CommonModule,
    AppContainerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    NgxPaginationModule,
    ComponentsModule,
    ElementsModule
  ],
  exports: [
    NavigationComponent,
    ContainerHeaderComponent,
    ContainerIconComponent,
    ListComponent,
    SettingsListComponent,
    MessageSectionComponent,
    MessageInfoSectionComponent
  ]
})
export class AppContainerModule {
}

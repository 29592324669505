import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Logout, Userstate} from 'src/app/store/user';
import {ApplicationState} from 'src/app/app.state';
import {initialState, userState} from 'src/app/store/user/user.state';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.css']
})
export class LoginContainerComponent implements OnInit {
  authenticationState: Observable<Userstate>;

  constructor(public store: Store<ApplicationState>) {
  }

  ngOnInit() {
    // on brower back navigation Userstate will be reset to initial state
    this.store.dispatch(new Logout(initialState));
    this.authenticationState = this.store.pipe(select(userState));
  }

}

import { Action } from '@ngrx/store';

export enum EnquiryActions {
  LOAD_ENQUIRIES = '[Enquiry] Load Enquiries',
  LOAD_ENQUIRIES_SUCCESS = '[Enquiry] Load Enquiries Success',
  LOAD_ENQUIRIES_FAILED = '[Enquiry] Load Enquiries Failed',
}

export class LoadEnquiries implements Action {
  readonly type: string = EnquiryActions.LOAD_ENQUIRIES;

  constructor(public payload: any) {

  }
}

export class LoadEnquiriesSuccess implements Action {
  readonly type: string = EnquiryActions.LOAD_ENQUIRIES_SUCCESS;

  constructor(public payload: any) {

  }
}

export class LoadEnquiriesFailed implements Action {
  readonly type: string = EnquiryActions.LOAD_ENQUIRIES_FAILED;

  constructor(public payload: any) {

  }
}

export type EnquiryAction =
  LoadEnquiries | LoadEnquiriesSuccess | LoadEnquiriesFailed;

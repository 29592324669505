import { Action } from '@ngrx/store';

import { IGeographicCode } from 'src/app/core/models/GeographicCode';

export enum GeographicCodeActions {
  LOAD_GEOGRAPHICCODES = '[Geographic Code] Load Geographic Codes',
  LOAD_GEOGRAPHICCODES_SUCCESS = '[Geographic Code] Load Geographic Codes Success',
  LOAD_GEOGRAPHICCODES_FAILED = '[Geographic Code] Load Geographic Codes Failed',

  SET_GEOGRAPHICCODE = '[Geographic Code] Set Geographic Code',
  SET_GEOGRAPHICCODES = '[Geographic Code] Set Geographic Codes',
  SET_MESSAGE = '[Geographic Code] Set Message',

  CREATE_GEOGRAPHICCODE = '[Geographic Code] Create Geographic Code',
  CREATE_GEOGRAPHICCODE_SUCCESS = '[Geographic Code] Create Geographic Code Success',
  CREATE_GEOGRAPHICCODE_FAILED = '[Geographic Code] Create Geographic Code Failed',

  UPDATE_GEOGRAPHICCODE = '[Geographic Code] Update Geographic Code',
  UPDATE_GEOGRAPHICCODE_SUCCESS = '[Geographic Code] Update Geographic Code Success',
  UPDATE_GEOGRAPHICCODE_FAILED = '[Geographic Code] Update Geographic Code Failed',

  REMOVE_GEOGRAPHICCODE = '[Geographic Code] Remove Geographic Code',
  REMOVE_GEOGRAPHICCODE_SUCCESS = '[Geographic Code] Remove Geographic Code Success',
  REMOVE_GEOGRAPHICCODE_FAILED = '[Geographic Code] Remove Geographic Code Failed'
}

export class LoadGeographicCodes implements Action {
  readonly type: string = GeographicCodeActions.LOAD_GEOGRAPHICCODES;

  constructor(public payload: any) {
  }
}

export class LoadGeographicCodesSuccess implements Action {
  readonly type: string = GeographicCodeActions.LOAD_GEOGRAPHICCODES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadGeographicCodesFailed implements Action {
  readonly type: string = GeographicCodeActions.LOAD_GEOGRAPHICCODES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetGeographicCode implements Action {
  readonly type: string = GeographicCodeActions.SET_GEOGRAPHICCODE;

  constructor(public payload: any) {
  }
}

export class SetGeographicCodes implements Action {
  readonly type: string = GeographicCodeActions.SET_GEOGRAPHICCODES;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = GeographicCodeActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateGeographicCode implements Action {
  readonly type: string = GeographicCodeActions.CREATE_GEOGRAPHICCODE;

  constructor(public payload: IGeographicCode) {
  }
}

export class CreateGeographicCodeSuccess implements Action {
  readonly type: string = GeographicCodeActions.CREATE_GEOGRAPHICCODE_SUCCESS;

  constructor(public payload: IGeographicCode) {
  }
}

export class CreateGeographicCodeFailed implements Action {
  readonly type: string = GeographicCodeActions.CREATE_GEOGRAPHICCODE_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateGeographicCode implements Action {
  readonly type: string = GeographicCodeActions.UPDATE_GEOGRAPHICCODE;

  constructor(public payload: IGeographicCode) {
  }
}

export class UpdateGeographicCodeSuccess implements Action {
  readonly type: string = GeographicCodeActions.UPDATE_GEOGRAPHICCODE_SUCCESS;

  constructor(public payload: IGeographicCode) {
  }
}

export class UpdateGeographicCodeFailed implements Action {
  readonly type: string = GeographicCodeActions.UPDATE_GEOGRAPHICCODE_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveGeographicCode implements Action {
  readonly type: string = GeographicCodeActions.REMOVE_GEOGRAPHICCODE;

  constructor(public payload: number) {
  }
}

export class RemoveGeographicCodeSuccess implements Action {
  readonly type: string = GeographicCodeActions.REMOVE_GEOGRAPHICCODE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveGeographicCodeFailed implements Action {
  readonly type: string = GeographicCodeActions.REMOVE_GEOGRAPHICCODE_FAILED;

  constructor(public payload: any) {
  }
}

export type GeographicCodeAction =
  LoadGeographicCodes | LoadGeographicCodesSuccess | LoadGeographicCodesFailed |
  SetGeographicCode | SetGeographicCodes | SetMessage |
  CreateGeographicCode | CreateGeographicCodeSuccess | CreateGeographicCodeFailed |
  UpdateGeographicCode | UpdateGeographicCodeSuccess | UpdateGeographicCodeFailed |
  RemoveGeographicCode | RemoveGeographicCodeSuccess | RemoveGeographicCodeFailed 

import {createSelector} from '@ngrx/store';
import {ApplicationState} from 'src/app/app.state';
import {IUser} from 'src/app/core/models/User';
import {ICompany} from 'src/app/core/models/Company';

export interface Userstate {
  isProcessing: boolean;
  isAuthenticated: boolean;
  authenticatedUser: IUser;
  companies: [ICompany];
  company_id: number;
  error: any;
  message: any;
}

export const initialState: Userstate = {
  isProcessing: false,
  isAuthenticated: false,
  authenticatedUser: null,
  companies: null,
  company_id: null,
  error: null,
  message: null
} as Userstate;

export const getApplicationState = (state: ApplicationState) => state;

// exports userState.user from ApplicationState
export const userState = createSelector(getApplicationState, (state: ApplicationState) => state.user);

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from 'src/app/core/guards/authentication.guard';

import { NavigationComponent } from './navigation/navigation.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    component: NavigationComponent
  },
  {
    path: 'enquiry',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./enquiry/enquiry.module').then(mod => mod.EnquiryModule)
  },
  {
    path: 'subscription',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./subscription/subscription.module').then(mod => mod.SubscriptionModule)
  },
  {
    path: 'company',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./company/company.module').then(mod => mod.CompanyModule)
  },
  {
    path: 'program',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./program/program.module').then(mod => mod.ProgramModule)
  },
  {
    path: 'theme',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./theme/theme.module').then(mod => mod.ThemeModule)
  },
  {
    path: 'profile',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./profile-management/profile-management.module').then(mod => mod.ProfileManagementModule)
  },
  {
    path: 'tour',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./tour/tour.module').then(mod => mod.TourModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppContainerRoutingModule {
}

import * as themeActions from './theme.actions';
import { ITheme } from 'src/app/core/models/Theme';

export function themeReducer(state: any, action: themeActions.ThemeAction): any {
  switch (action.type) {
    case themeActions.ThemeActions.LOAD_THEMES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case themeActions.ThemeActions.LOAD_THEMES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        themes: action.payload,
        message: null
      };
    }
    case themeActions.ThemeActions.LOAD_THEMES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        themes: null,
        message: { status: 412, error: action.payload }
      };
    }
    case themeActions.ThemeActions.SET_THEME: {
      return {
        ...state,
        isProcessing: false,
        theme: action.payload,
        message: null
      };
    }
    case themeActions.ThemeActions.SET_THEMES: {
      return {
        ...state,
        isProcessing: false,
        themes: action.payload,
        message: null
      };
    }
    case themeActions.ThemeActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case themeActions.ThemeActions.CREATE_THEME: {
      return {
        ...state,
        isProcessing: true,
        theme: null,
        message: null
      };
    }
    case themeActions.ThemeActions.CREATE_THEME_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        themes: [...state.themes, ...action.payload],
        message: { status: 201, info: 'Theme created successfully' }
      };
    }
    case themeActions.ThemeActions.CREATE_THEME_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case themeActions.ThemeActions.UPDATE_THEME: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case themeActions.ThemeActions.UPDATE_THEME_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        theme: null,
        themes: [
          ...state.themes.filter((theme: ITheme) => theme.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Theme updated successfully' }
      };
    }
    case themeActions.ThemeActions.UPDATE_THEME_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case themeActions.ThemeActions.REMOVE_THEME: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case themeActions.ThemeActions.REMOVE_THEME_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        theme: null,
        themes: state.themes.filter((theme: ITheme) => theme.id !== action.payload),
        message: { status: 200, info: 'Theme removed successfully' }
      };
    }
    case themeActions.ThemeActions.REMOVE_THEME_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    default: {
      return state;
    }
  }
}

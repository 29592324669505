import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as programActions from './program.actions';
import { ProgramService } from 'src/app/core/services/program.service';
import { ApplicationState } from 'src/app/app.state';
import { programs } from './program.state';

@Injectable()
export class ProgramEffects {
  @Effect()
  public loadProgramsEffect = this.actions.pipe(
    ofType(programActions.ProgramActions.LOAD_PROGRAMS),
    withLatestFrom(this.store.pipe(select(programs))),
    filter(([_, programs]) => {
      if (programs) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.programService.fetchPrograms().pipe(
        map(response => (
          new programActions.LoadProgramsSuccess(response)
        )),
        catchError((error) => of(
          new programActions.LoadProgramsFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createProgramEffect = this.actions.pipe(
    ofType(programActions.ProgramActions.CREATE_PROGRAM),
    exhaustMap((action: programActions.CreateProgram) =>
      this.programService.createProgram(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new programActions.CreateProgramSuccess(response);
          }),
          catchError(error => of(new programActions.CreateProgramFailed(error)))
        )
    )
  );
  @Effect()
  public updateProgramByIdEffect = this.actions.pipe(
    ofType(programActions.ProgramActions.UPDATE_PROGRAM),
    exhaustMap((action: programActions.UpdateProgram) =>
      this.programService.updateProgram(action.payload)
        .pipe(
          map(() => new programActions.UpdateProgramSuccess(action.payload)),
          catchError(error => of(new programActions.UpdateProgramFailed(error)))
        )
    )
  );
  @Effect()
  public removeProgramByIdEffect = this.actions.pipe(
    ofType(programActions.ProgramActions.REMOVE_PROGRAM),
    switchMap((action: programActions.RemoveProgram) =>
      this.programService.removeProgram(action.payload)
        .pipe(
          map(() => new programActions.RemoveProgramSuccess(action.payload)),
          catchError(error => of(new programActions.RemoveProgramFailed(error)))
        )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public programService: ProgramService
  ) {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.css']
})
export class SettingsListComponent implements OnInit {
  paginationLimit: number;

  @Input() list: any;
  @Input() listSubHeaderName: string;
  @Input() searchText: string;
  @Input() searchFields: string;

  @Output() update = new EventEmitter();

  constructor() {
    this.paginationLimit = 10;
  }

  updateEvent = (listElement: any) => {
    this.update.emit(listElement);
  };

  ngOnInit() {
  }

}

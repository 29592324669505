import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})
export class CancelButtonComponent implements OnInit {
  @Input() btnText: string;

  @Output() cancel = new EventEmitter();

  constructor() {
  }

  cancelEvent = () => {
    this.cancel.emit();
  };

  ngOnInit() {
  }

}

import { Action } from '@ngrx/store';

import { ITheme } from 'src/app/core/models/Theme';

export enum ThemeActions {
  LOAD_THEMES = '[Theme] Load Themes',
  LOAD_THEMES_SUCCESS = '[Theme] Load Themes Success',
  LOAD_THEMES_FAILED = '[Theme] Load Themes Failed',

  SET_THEME = '[Theme] Set Theme',
  SET_THEMES = '[Theme] Set Themes',
  SET_MESSAGE = '[Theme] Set Message',

  CREATE_THEME = '[Theme] Create Theme',
  CREATE_THEME_SUCCESS = '[Theme] Create Theme Success',
  CREATE_THEME_FAILED = '[Theme] Create Theme Failed',

  UPDATE_THEME = '[Theme] Update Theme',
  UPDATE_THEME_SUCCESS = '[Theme] Update Theme Success',
  UPDATE_THEME_FAILED = '[Theme] Update Theme Failed',

  REMOVE_THEME = '[Theme] Remove Theme',
  REMOVE_THEME_SUCCESS = '[Theme] Remove Theme Success',
  REMOVE_THEME_FAILED = '[Theme] Remove Theme Failed'
}

export class LoadThemes implements Action {
  readonly type: string = ThemeActions.LOAD_THEMES;

  constructor(public payload: any) {
  }
}

export class LoadThemesSuccess implements Action {
  readonly type: string = ThemeActions.LOAD_THEMES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadThemesFailed implements Action {
  readonly type: string = ThemeActions.LOAD_THEMES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTheme implements Action {
  readonly type: string = ThemeActions.SET_THEME;

  constructor(public payload: any) {
  }
}

export class SetThemes implements Action {
  readonly type: string = ThemeActions.SET_THEMES;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = ThemeActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateTheme implements Action {
  readonly type: string = ThemeActions.CREATE_THEME;

  constructor(public payload: ITheme) {
  }
}

export class CreateThemeSuccess implements Action {
  readonly type: string = ThemeActions.CREATE_THEME_SUCCESS;

  constructor(public payload: ITheme) {
  }
}

export class CreateThemeFailed implements Action {
  readonly type: string = ThemeActions.CREATE_THEME_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTheme implements Action {
  readonly type: string = ThemeActions.UPDATE_THEME;

  constructor(public payload: ITheme) {
  }
}

export class UpdateThemeSuccess implements Action {
  readonly type: string = ThemeActions.UPDATE_THEME_SUCCESS;

  constructor(public payload: ITheme) {
  }
}

export class UpdateThemeFailed implements Action {
  readonly type: string = ThemeActions.UPDATE_THEME_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTheme implements Action {
  readonly type: string = ThemeActions.REMOVE_THEME;

  constructor(public payload: number) {
  }
}

export class RemoveThemeSuccess implements Action {
  readonly type: string = ThemeActions.REMOVE_THEME_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveThemeFailed implements Action {
  readonly type: string = ThemeActions.REMOVE_THEME_FAILED;

  constructor(public payload: any) {
  }
}

export type ThemeAction =
  LoadThemes | LoadThemesSuccess | LoadThemesFailed |
  SetTheme | SetThemes | SetMessage |
  CreateTheme | CreateThemeSuccess | CreateThemeFailed |
  UpdateTheme | UpdateThemeSuccess | UpdateThemeFailed |
  RemoveTheme | RemoveThemeSuccess | RemoveThemeFailed 

import { Action } from '@ngrx/store';

export enum SubscriptionActions {
  LOAD_SUBSCRIPTIONS = '[Subscription] Load Subscriptions',
  LOAD_SUBSCRIPTIONS_SUCCESS = '[Subscription] Load Subscriptions Success',
  LOAD_SUBSCRIPTIONS_FAILED = '[Subscription] Load Subscriptions Failed',
}

export class LoadSubscriptions implements Action {
  readonly type: string = SubscriptionActions.LOAD_SUBSCRIPTIONS;

  constructor(public payload: any) {

  }
}

export class LoadSubscriptionsSuccess implements Action {
  readonly type: string = SubscriptionActions.LOAD_SUBSCRIPTIONS_SUCCESS;

  constructor(public payload: any) {

  }
}

export class LoadSubscriptionsFailed implements Action {
  readonly type: string = SubscriptionActions.LOAD_SUBSCRIPTIONS_FAILED;

  constructor(public payload: any) {

  }
}

export type SubscriptionAction =
  LoadSubscriptions | LoadSubscriptionsSuccess | LoadSubscriptionsFailed;

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, keys: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText || !keys) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(item =>
      keys
        .split(', ')
        .some(
          key =>
            item.hasOwnProperty(key) &&
            new RegExp(searchText, 'gi').test(item[key])
        )
    );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ElementsRoutingModule } from './elements-routing.module';

// Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { PrecedingZeroPipe } from './pipes/preceding-zero.pipe';
import { DecimalPointPipe } from './pipes/decimal-point.pipe';

// Directives
import { NumericMaskFieldDirective } from './directives/numeric-mask.directive';
import { DateMaskFieldDirective } from './directives/date-mask.directive';

@NgModule({
  declarations: [
    FilterPipe,
    PrecedingZeroPipe,
    DecimalPointPipe,
    NumericMaskFieldDirective,
    DateMaskFieldDirective
  ],
  imports: [
    CommonModule,
    ElementsRoutingModule
  ],
  exports: [
    FilterPipe,
    PrecedingZeroPipe,
    DecimalPointPipe,
    NumericMaskFieldDirective,
    DateMaskFieldDirective
  ]
})
export class ElementsModule {
}

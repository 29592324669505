import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ISubscription } from '../models/Subscription';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(private http: HttpClient) {

  }

  public fetchSubscription = (): Observable<[ISubscription]> => {
    return this.http.get<any>(`${environment.api_service}/customer/subscription`)
      .pipe(
        map((response: any) => {
          return response.data || response || [];
        }),
        catchError(this.handleError)
      );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }
}

import { createSelector } from '@ngrx/store';

import * as userStore from './store/user';
import * as commonStore from './store/common';
import * as enquiryStore from './store/enquiry';
import * as subscriptionStore from './store/subscription';
import * as companyStore from './store/company';
import * as programStore from './store/program';
import * as geographicCodeStore from './store/geographic.code';
import * as themeStore from './store/theme';
import * as directorStore from './store/director';
import * as tourStore from './store/tour';

export interface ApplicationState {
  user: userStore.Userstate;
  commonState: commonStore.CommonState;
  enquiryState: enquiryStore.EnquiryState;
  subscriptionState: subscriptionStore.SubscriptionState;
  companyState: companyStore.CompanyState;
  programState: programStore.ProgramState;
  geographicCodeState: geographicCodeStore.GeographicCodeState;
  themeState: themeStore.ThemeState;
  directorState: directorStore.DirectorState;
  tourState: tourStore.TourState;
}

export const initialState: ApplicationState = {
  user: userStore.initialState,
  commonState: commonStore.initialState,
  enquiryState: enquiryStore.initialState,
  subscriptionState: subscriptionStore.initialState,
  companyState: companyStore.initialState,
  programState: programStore.initialState,
  geographicCodeState: geographicCodeStore.initialState,
  themeState: themeStore.initialState,
  directorState: directorStore.initialState,
  tourState: tourStore.initialState,
};

export const getApplicationState = (state: ApplicationState) => state;
export const applicationState = createSelector(getApplicationState, (state: ApplicationState) => state);

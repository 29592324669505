import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import {
  IBookingStatuses,
  IPaymentStatuses,
  ITour,
  ITourAddon,
  ITourBooking,
  ITourDiscount,
  ITourItinerary,
  ITourPassenger,
  ITourPassengerAddon,
  ITourPriceCategory,
  IPaymentMode,
  IPayment
} from '../models/Tour';

@Injectable({ providedIn: 'root' })
export class TourService {
  constructor(private http: HttpClient) {
  }

  /* Fetch all tourDetails */
  public fetchTours(searchObject?, paginationObject?: { page: number, limit: number }): Observable<ITour[]> {
    return this.http.get<any>(`${environment.api_service}/api/tour/detail`, {
      params: { search: JSON.stringify(searchObject || {}), paginationObject: JSON.stringify(paginationObject || {}) }
    }).pipe(
      map((response: any) => response || { data: [], recordCount: 0 }),
      catchError(this.handleError)
    );
  }

  /* Fetch tour detail by id */
  public fetchTourDetail = (id: number): Observable<ITour> => {
    return this.http.get<any>(`${environment.api_service}/api/tour/detail/${id}`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return {};
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create tour detail */
  public createTourDetail = (tour: ITour): Observable<any> => {
    return this.http.post<any>(`${environment.api_service}/api/tour/detail`, tour).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  };

  /* Update tour detail */
  public updateTourDetail = (tour: ITour): Observable<any> => {
    return this.http.put<any>(`${environment.api_service}/api/tour/detail/${tour.id}`, tour).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour savings by tourId */
  public fetchTourDiscounts = (tourId: number): Observable<ITourDiscount[]> => {
    return this.http.get<any>(`${environment.api_service}/api/tour/${tourId}/savings`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour saving */
  public createTourDiscount = (saving: any): any => {
    return this.http.post<any>(`${environment.api_service}/api/tour/savings`, saving).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour saving by id */
  public updateTourDiscount = (saving: any): any => {
    return this.http.put<any>(`${environment.api_service}/api/tour/saving/${saving.id}`, saving).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour saving by id */
  public removeTourDiscount = (id: number): any => {
    return this.http.delete<any>(`${environment.api_service}/api/tour/saving/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour savings by tourId for todays date */
  public fetchTourOffers = (tourId: number, date: string): Observable<ITourDiscount[]> => {
    return this.http.get<any>(`${environment.api_service}/api/tour/${tourId}/savings/${date}`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch tour addons by tourId */
  public fetchTourAddons = (tourId: number): Observable<ITourAddon[]> => {
    return this.http.get<any>(`${environment.api_service}/api/tour/${tourId}/addons`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour addon */
  public createTourAddon = (addon: any): any => {
    return this.http.post<any>(`${environment.api_service}/api/tour/addons`, addon).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour addon by id */
  public updateTourAddon = (addon: any): any => {
    return this.http.put<any>(`${environment.api_service}/api/tour/addons/${addon.id}`, addon).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour addon by id */
  public removeTourAddon = (id: number): any => {
    return this.http.delete<any>(`${environment.api_service}/api/tour/addons/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour price categories by tourId */
  public fetchTourPriceCategories = (tourId: number): Observable<ITourPriceCategory[]> => {
    return this.http.get<any>(`${environment.api_service}/api/tour/${tourId}/price/category`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour price category */
  public createTourPriceCategory = (priceCategory: any): any => {
    return this.http.post<any>(`${environment.api_service}/api/tour/price/category`, priceCategory).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour price category by id */
  public updateTourPriceCategory = (priceCategory: any): any => {
    return this.http.put<any>(`${environment.api_service}/api/tour/price/category/${priceCategory.id}`, priceCategory).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour price category by id */
  public removeTourPriceCategory = (id: number): any => {
    return this.http.delete<any>(`${environment.api_service}/api/tour/price/category/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour addon price categories by price category id */
  public fetchTourAddonPriceCategories = (priceCategoryId: number): any => {
    return this.http.get<any>(`${environment.api_service}/api/tour/price/category/${priceCategoryId}/addons`)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  };

  /* Fetch tour addon price categories by tour id and price category id */
  public fetchAddonsForTourAndPriceCategory = (tourId: number, priceCategoryId: number): any => {
    return this.http.get<any>(`${environment.api_service}/api/tour/${tourId}/price/category/${priceCategoryId}/addons`)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  };

  /* Insert addons for price category */
  public insertAddonsForPriceCategory = (addon: { price_category_id: number, add_on_id: number, occupancy_type: string }): any => {
    return this.http.post<any>(`${environment.api_service}/api/tour/price/category/addon`, addon).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Delete addons for price category */
  public deleteAddonsForPriceCategory = (addon: { price_category_id: number, add_on_id: number, occupancy_type: string }): any => {
    return this.http.delete<any>(`${environment.api_service}/api/tour/price/category/${addon.price_category_id}/addon?add_on_id=${addon.add_on_id}&occupancy_type=${addon.occupancy_type}`)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  };

  /* Fetch price category addons for passenger */
  public fetchPriceCategoryAddonsForTraveler(passengerId: number): Observable<ITourPassengerAddon[]> {
    return this.http.get<any>(`${environment.api_service}/api/booking/traveler/${passengerId}/price/category/addons`)
      .pipe(
        map(response => {
          if (response && response.data) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError(this.handleError)
      );
  }

  /* Fetch tour itineraries by tourId */
  public fetchTourItineraries = (tourId: number): Observable<ITourItinerary[]> => {
    return this.http.get<any>(`${environment.api_service}/api/itinerary/tour/${tourId}`).pipe(
      map(response => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour itinerary */
  public createTourItinerary = (itinerary: any): any => {
    return this.http.post<any>(`${environment.api_service}/api/itinerary`, itinerary).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour itinerary by id */
  public updateTourItinerary = (itinerary: any): any => {
    return this.http.put<any>(`${environment.api_service}/api/itinerary/${itinerary.id}`, itinerary).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour itinerary by id */
  public removeTourItinerary = (id: number): any => {
    return this.http.delete<any>(`${environment.api_service}/api/itinerary/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch all booking statuses */
  public fetchBookingStatuses = (): Observable<IBookingStatuses[]> => {
    return this.http.get<any>(`${environment.api_service}/api/booking/statuses`).pipe(
      map((response: any) => {
        if (response) {
          return response.data;
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch all payment statuses */
  public fetchPaymentStatuses = (): Observable<IPaymentStatuses[]> => {
    return this.http.get<any>(`${environment.api_service}/api/booking/payment/statuses`).pipe(
      map((response: any) => {
        if (response) {
          return response.data;
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch tour bookings by tour */
  public fetchTourBookings = (tourId: number): Observable<ITourBooking[]> => {
    return this.http.get<ITourBooking[]>(`${environment.api_service}/api/booking/tour/${tourId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch tour booking by ID */
  public fetchTourBookingByID = (bookingId: number): Observable<ITourBooking> => {
    return this.http.get<ITourBooking>(`${environment.api_service}/api/booking/${bookingId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour booking */
  public createTourBooking = (booking: ITourBooking) => {
    return this.http.post<ITourBooking>(`${environment.api_service}/api/booking`, booking).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour booking by id */
  public updateTourBooking = (booking: ITourBooking) => {
    return this.http.put<ITourBooking>(`${environment.api_service}/api/booking/${booking.id}`, booking).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove tour booking by id */
  public removeTourBooking = (bookingId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/booking/${bookingId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch total traveler fee for booking */
  public fetchTotalFeeForTraveler = (bookingId: number) => {
    return this.http.get<any>(`${environment.api_service}/api/booking/${bookingId}/totaltravelerfee`).pipe(
      map((response: any) => {
        if (response && response.data && response.data[0]) {
          return response.data[0];
        } else {
          return { total_traveler_fee: 0, total_traveler_fee_after_saving: 0 };
        }
      }),
      catchError(this.handleError)
    );
  }

  /* Fetch payment modes */
  public fetchPaymentModes(): Observable<IPaymentMode[]> {
    return this.http.get<any>(`${environment.api_service}/api/booking/payment/modes`).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  };

  /* Fetch payments history by booking id */
  public fetchPaymentsByBookingId(bookingId: number): Observable<IPayment[]> {
    return this.http.get<any>(`${environment.api_service}/api/booking/payments/${bookingId}`).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  };

  /* Create payment */
  public createPayment = (payment: IPayment) => {
    return this.http.post<IPayment>(`${environment.api_service}/api/booking/payment`, payment).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour passengers by booking */
  public fetchTourPassengersByBooking = (bookingId: number): Observable<ITourPassenger[]> => {
    return this.http.get<ITourPassenger[]>(`${environment.api_service}/api/booking/${bookingId}/travelers`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Fetch tour passenger by ID */
  public fetchTourPassengerByID = (passengerId: number): Observable<ITourPassenger> => {
    return this.http.get<ITourPassenger>(`${environment.api_service}/api/booking/traveler/${passengerId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new tour passenger */
  public createTourPassenger = (passenger: ITourPassenger) => {
    return this.http.post<ITourPassenger>(`${environment.api_service}/api/booking/traveler`, passenger).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update tour passenger by id */
  public updateTourPassenger = (passenger: ITourPassenger) => {
    return this.http.put<ITourPassenger>(`${environment.api_service}/api/booking/traveler/${passenger.id}`, passenger).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove tour passenger by id */
  public removeTourPassenger = (passengerId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/booking/traveler/${passengerId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    console.info(error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

import * as userActions from './user.actions';

export function userReducer(state: any, action: userActions.LoginAction): any {
  switch (action.type) {
    case userActions.LoginActions.LOGIN: {
      return {
        ...state,
        isProcessing: true,
        isAuthenticated: false,
        error: null,
        message: 'Authenticating...'
      };
    }
    case userActions.LoginActions.LOGIN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isAuthenticated: true,
        authenticatedUser: action.payload,
        error: null,
        message: null
      };
    }
    case userActions.LoginActions.LOGIN_FAILED: {
      return {
        ...state,
        isProcessing: false,
        isAuthenticated: false,
        authenticatedUser: null,
        message: null,
        error: 'Unable to authenticate user credentials'
      };
    }
    case userActions.LoginActions.LOAD_COMPANIES: {
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: 'Loading Companies...'
      };
    }
    case userActions.LoginActions.LOAD_COMPANIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        companies: action.payload,
        company_id: null,
        error: null,
        message: null
      };
    }
    case userActions.LoginActions.LOAD_COMPANIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        companies: null,
        company_id: null,
        error: { status: 412, error: action.payload },
        message: null
      };
    }
    case userActions.LoginActions.LOGOUT: {
      return {
        ...state,
        isProcessing: false,
        isAuthenticated: false,
        authenticatedUser: null,
        companies: null,
        company_id: null,
        error: null,
        message: null
      };
    }
    case userActions.PersonalizeCompanyActions.SET_COMPANY: {
      return {
        ...state,
        error: null,
        message: 'Redirecting to dashboard...'
      };
    }
    case userActions.PersonalizeCompanyActions.SET_COMPANY_SUCCESS: {
      return {
        ...state,
        company_id: action.payload,
        error: null
      };
    }
    default: {
      return state;
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decimalPoint'
})
export class DecimalPointPipe implements PipeTransform {

  transform(value: string, type: number): any {
    if (isNaN(Number(value))) {
      throw new Error(`${value} is not a acceptable number`);
    }
    return parseFloat(value).toFixed(type);
  }

}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateField]'
})

export class DateMaskFieldDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = event.target.value;

    if (event.target.value) {
      event.target.value = initalValue.replace(/[^0-9/]*/g, '');
      if ((event.target.value.length === 2 || event.target.value.length === 5) && event.data != '/' && event.data !== null) {
        event.target.value = event.target.value + '/';
      }
      // if (event.target.selectionStart >= 10) {
      // } 
      if (initalValue !== event.target.value) {
        event.stopPropagation();
      }
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.css']
})
export class CreateButtonComponent implements OnInit {
  @Input() entityName: string;
  @Input() btnText: string;
  @Input() disabled: boolean;

  @Output() create = new EventEmitter();

  constructor() {
  }

  createEvent = () => {
    this.create.emit();
  };

  ngOnInit() {
  }

}

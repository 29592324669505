import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'precedingZero'
})
export class PrecedingZeroPipe implements PipeTransform {

  transform(value: string, numberOfZeros: number): any {
    let prefix = '';
    for (let index = 0; index < numberOfZeros; index++) {
      if (String(value).length < 2) {
        prefix += '0';
      }
    }
    return `${prefix}${parseFloat(value)}`;
  }

}

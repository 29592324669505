import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.css']
})
export class SaveButtonComponent implements OnInit {
  @Input() btnText: string;
  @Input() isProcessing: boolean;
  @Input() isFormValid: boolean;

  @Output() save = new EventEmitter();

  constructor() {
  }

  saveEvent = () => {
    this.save.emit();
  };

  ngOnInit() {
  }

}

import * as subscriptionActions from './subscription.actions';

export function subscriptionReducer(state: any, action: subscriptionActions.SubscriptionAction): any {
  switch (action.type) {
    case subscriptionActions.SubscriptionActions.LOAD_SUBSCRIPTIONS: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case subscriptionActions.SubscriptionActions.LOAD_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        subscriptions: action.payload,
        message: null
      };
    }
    case subscriptionActions.SubscriptionActions.LOAD_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        subscriptions: null,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    default: {
      return state;
    }
  }
}

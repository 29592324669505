import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as tourActions from './tour.actions';
import { TourService } from 'src/app/core/services/tour.service';
import { ApplicationState } from 'src/app/app.state';
import {
  addons,
  booking,
  bookings,
  bookingStatuses,
  discounts,
  itineraries,
  offers,
  passengers,
  paymentStatuses,
  priceCategories,
  tour,
  tours,
  paymentModes,
  paymentHistory,
  selectedBookingForPayment
} from './tour.state';
import { ITourBooking } from "src/app/core/models/Tour";

@Injectable()
export class TourEffects {
  @Effect()
  public loadToursEffect = this.actions.pipe(
    ofType(tourActions.TourActions.LOAD_TOURS),
    withLatestFrom(this.store.pipe(select(tours))),
    filter(([_, tours]) => {
      if (tours) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTours) =>
      this.tourService.fetchTours(action.search, action.pagination).pipe(
        map(response => (
          new tourActions.LoadToursSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadToursFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadMoreToursEffect = this.actions.pipe(
    ofType(tourActions.TourActions.LOAD_MORE_TOURS),
    mergeMap((action: tourActions.LoadMoreTours) =>
      this.tourService.fetchTours(action.search, action.pagination).pipe(
        map(response => (
          new tourActions.LoadMoreToursSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadMoreToursFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourByIDEffect = this.actions.pipe(
    ofType(tourActions.TourActions.LOAD_TOUR_BY_ID),
    withLatestFrom(this.store.pipe(select(tour))),
    filter(([action, tour]) => {
      if (tour && tour.id && tour.id == (action as tourActions.LoadTourByID).payload) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourByID) =>
      this.tourService.fetchTourDetail(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourByIDSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourByIDFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourDiscountsEffect = this.actions.pipe(
    ofType(tourActions.TourDiscountActions.LOAD_TOUR_DISCOUNTS),
    withLatestFrom(this.store.pipe(select(discounts))),
    filter(([action, discounts]) => {
      if (
        discounts &&
        discounts[0] &&
        Number(discounts[0].tour_id) === (action as tourActions.LoadTourDiscounts).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourDiscounts) =>
      this.tourService.fetchTourDiscounts(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourDiscountsSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourDiscountsFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourEffect = this.actions.pipe(
    ofType(tourActions.TourActions.CREATE_TOUR),
    exhaustMap((action: tourActions.CreateTour) =>
      this.tourService.createTourDetail(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreateTourSuccess(response)
          }),
          catchError(error => of(new tourActions.CreateTourFailed(error)))
        )
    )
  );
  @Effect()
  public updateTourByIdEffect = this.actions.pipe(
    ofType(tourActions.TourActions.UPDATE_TOUR),
    exhaustMap((action: tourActions.UpdateTour) =>
      this.tourService.updateTourDetail(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourSuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourFailed(error)))
        )
    )
  );
  @Effect()
  public createTourDiscountEffect = this.actions.pipe(
    ofType(tourActions.TourDiscountActions.CREATE_TOUR_DISCOUNT),
    exhaustMap((action: tourActions.CreateTourDiscount) => {
      return this.tourService.createTourDiscount(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreateTourDiscountSuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourDiscountFailed(error)))
        )
    }
    )
  );
  @Effect()
  public updateTourDiscountByIdEffect = this.actions.pipe(
    ofType(tourActions.TourDiscountActions.UPDATE_TOUR_DISCOUNT),
    exhaustMap((action: tourActions.UpdateTourDiscount) =>
      this.tourService.updateTourDiscount(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourDiscountSuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourDiscountFailed(error)))
        )
    )
  );
  @Effect()
  public removeTourDiscountByIdEffect = this.actions.pipe(
    ofType(tourActions.TourDiscountActions.REMOVE_TOUR_DISCOUNT),
    switchMap((action: tourActions.RemoveTourDiscount) =>
      this.tourService.removeTourDiscount(action.payload)
        .pipe(
          map(() => new tourActions.RemoveTourDiscountSuccess(action.payload)),
          catchError(error => of(new tourActions.RemoveTourDiscountFailed(error, action.payload)))
        )
    )
  );
  @Effect()
  public loadTourOffersEffect = this.actions.pipe(
    ofType(tourActions.TourDiscountActions.LOAD_TOUR_OFFERS),
    withLatestFrom(this.store.pipe(select(offers))),
    filter(([action, offers]) => {
      if (
        offers &&
        offers[0] &&
        Number(offers[0].tour_id) === (action as tourActions.LoadTourOffers).payload.tourId
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourOffers) =>
      this.tourService.fetchTourOffers(action.payload.tourId, action.payload.date).pipe(
        map(response => (
          new tourActions.LoadTourOffersSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourOffersFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourAddonsEffect = this.actions.pipe(
    ofType(tourActions.TourAddonActions.LOAD_TOUR_ADDONS),
    withLatestFrom(this.store.pipe(select(addons))),
    filter(([action, addons]) => {
      if (
        addons &&
        addons[0] &&
        Number(addons[0].tour_id) === (action as tourActions.LoadTourAddons).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourAddons) =>
      this.tourService.fetchTourAddons(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourAddonsSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourAddonsFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourAddonEffect = this.actions.pipe(
    ofType(tourActions.TourAddonActions.CREATE_TOUR_ADDON),
    exhaustMap((action: tourActions.CreateTourAddon) =>
      this.tourService.createTourAddon(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreateTourAddonSuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourAddonFailed(error)))
        )
    )
  );
  @Effect()
  public updateTourAddonByIdEffect = this.actions.pipe(
    ofType(tourActions.TourAddonActions.UPDATE_TOUR_ADDON),
    exhaustMap((action: tourActions.UpdateTourAddon) =>
      this.tourService.updateTourAddon(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourAddonSuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourAddonFailed(error)))
        )
    )
  );
  @Effect()
  public removeTourAddonByIdEffect = this.actions.pipe(
    ofType(tourActions.TourAddonActions.REMOVE_TOUR_ADDON),
    switchMap((action: tourActions.RemoveTourAddon) =>
      this.tourService.removeTourAddon(action.payload)
        .pipe(
          map(() => new tourActions.RemoveTourAddonSuccess(action.payload)),
          catchError(error => of(new tourActions.RemoveTourAddonFailed(error, action.payload)))
        )
    )
  );
  @Effect()
  public loadTourPriceCategoriesEffect = this.actions.pipe(
    ofType(tourActions.TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES),
    withLatestFrom(this.store.pipe(select(priceCategories))),
    filter(([action, priceCategories]) => {
      if (
        priceCategories &&
        priceCategories[0] &&
        Number(priceCategories[0].tour_id) === (action as tourActions.LoadTourPriceCategories).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourPriceCategories) =>
      this.tourService.fetchTourPriceCategories(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourPriceCategoriesSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourPriceCategoriesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourPriceCategoriesEffect = this.actions.pipe(
    ofType(tourActions.TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY),
    exhaustMap((action: tourActions.CreateTourPriceCategory) =>
      this.tourService.createTourPriceCategory(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreateTourPriceCategorySuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourPriceCategoryFailed(error)))
        )
    )
  );
  @Effect()
  public updateTourPriceCategoriesByIdEffect = this.actions.pipe(
    ofType(tourActions.TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY),
    exhaustMap((action: tourActions.UpdateTourPriceCategory) =>
      this.tourService.updateTourPriceCategory(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourPriceCategorySuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourPriceCategoryFailed(error)))
        )
    )
  );
  @Effect()
  public removeTourPriceCategoriesByIdEffect = this.actions.pipe(
    ofType(tourActions.TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY),
    switchMap((action: tourActions.RemoveTourPriceCategory) =>
      this.tourService.removeTourPriceCategory(action.payload)
        .pipe(
          map(() => new tourActions.RemoveTourPriceCategorySuccess(action.payload)),
          catchError(error => of(new tourActions.RemoveTourPriceCategoryFailed(error, action.payload)))
        )
    )
  );
  @Effect()
  public loadTourAddonPriceCategoriesEffect = this.actions.pipe(
    ofType(tourActions.TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES),
    exhaustMap((action: tourActions.LoadTourAddonPriceCategories) =>
      this.tourService.fetchTourAddonPriceCategories(action.payload).pipe(
        map((response: any) => (
          new tourActions.LoadTourAddonPriceCategoriesSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourAddonPriceCategoriesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadAddonForTourAndPriceCategoryEffect = this.actions.pipe(
    ofType(tourActions.TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY),
    exhaustMap((action: tourActions.LoadAddonsForTourAndPriceCategory) =>
      this.tourService.fetchAddonsForTourAndPriceCategory(action.payload.tourId, action.payload.priceCategoryId).pipe(
        map((response: any) => (
          new tourActions.LoadAddonsForTourAndPriceCategorySuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadAddonsForTourAndPriceCategoryFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadAddonForPassengerByPriceCategoryEffect = this.actions.pipe(
    ofType(tourActions.TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_PASSENGER),
    exhaustMap((action: tourActions.LoadAddonsForPassenger) =>
      this.tourService.fetchPriceCategoryAddonsForTraveler(action.payload).pipe(
        map(response => (
          new tourActions.LoadAddonsForPassengerSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadAddonsForPassengerFailed(error)
        ))
      )
    )
  );
  @Effect()
  public insertAddonForPriceCategoryEffect = this.actions.pipe(
    ofType(tourActions.TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY),
    switchMap((action: tourActions.InsertAddonForPriceCategory) =>
      this.tourService.insertAddonsForPriceCategory(action.payload)
        .pipe(
          map(() => new tourActions.InsertAddonForPriceCategorySuccess(action.payload)),
          catchError(error => of(new tourActions.InsertAddonForPriceCategoryFailed(error)))
        )
    )
  );
  @Effect()
  public deletetAddonForPriceCategoryEffect = this.actions.pipe(
    ofType(tourActions.TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY),
    switchMap((action: tourActions.DeletetAddonForPriceCategory) =>
      this.tourService.deleteAddonsForPriceCategory(action.payload)
        .pipe(
          map(() => new tourActions.DeletetAddonForPriceCategorySuccess(action.payload)),
          catchError(error => of(new tourActions.DeletetAddonForPriceCategoryFailed(error)))
        )
    )
  );
  @Effect()
  public loadTourItinerariesEffect = this.actions.pipe(
    ofType(tourActions.TourItineraryActions.LOAD_TOUR_ITINERARIES),
    withLatestFrom(this.store.pipe(select(itineraries))),
    filter(([action, itineraries]) => {
      if (
        itineraries &&
        itineraries[0] &&
        Number(itineraries[0].tour_id) === (action as tourActions.LoadTourItineraries).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourItineraries) =>
      this.tourService.fetchTourItineraries(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourItinerariesSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourItinerariesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourItineraryEffect = this.actions.pipe(
    ofType(tourActions.TourItineraryActions.CREATE_TOUR_ITINERARY),
    exhaustMap((action: tourActions.CreateTourItinerary) =>
      this.tourService.createTourItinerary(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreateTourItinerarySuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourItineraryFailed(error)))
        )
    )
  );
  @Effect()
  public updateTourItineraryByIdEffect = this.actions.pipe(
    ofType(tourActions.TourItineraryActions.UPDATE_TOUR_ITINERARY),
    exhaustMap((action: tourActions.UpdateTourItinerary) =>
      this.tourService.updateTourItinerary(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourItinerarySuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourItineraryFailed(error)))
        )
    )
  );
  @Effect()
  public removeTourItineraryByIdEffect = this.actions.pipe(
    ofType(tourActions.TourItineraryActions.REMOVE_TOUR_ITINERARY),
    switchMap((action: tourActions.RemoveTourItinerary) =>
      this.tourService.removeTourItinerary(action.payload)
        .pipe(
          map(() => new tourActions.RemoveTourItinerarySuccess(action.payload)),
          catchError(error => of(new tourActions.RemoveTourItineraryFailed(error)))
        )
    )
  );
  @Effect()
  public loadBookingStatusesEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_BOOKING_STATUSES),
    withLatestFrom(this.store.pipe(select(bookingStatuses))),
    filter(([_, bookingStatuses]) => {
      if (bookingStatuses) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.tourService.fetchBookingStatuses().pipe(
        map(response => (
          new tourActions.LoadBookingStatusesSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadBookingStatusesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadPaymentStatusesEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_PAYMENT_STATUSES),
    withLatestFrom(this.store.pipe(select(paymentStatuses))),
    filter(([_, paymentStatuses]) => {
      if (paymentStatuses) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.tourService.fetchPaymentStatuses().pipe(
        map(response => (
          new tourActions.LoadPaymentStatusesSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadPaymentStatusesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourBookingsEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_TOUR_BOOKINGS),
    withLatestFrom(this.store.pipe(select(bookings))),
    filter(([action, bookings]) => {
      if (
        bookings &&
        bookings[0] &&
        Number(bookings[0].tour_id) === (action as tourActions.LoadTourBookings).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourBookings) =>
      this.tourService.fetchTourBookings(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourBookingsSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourBookingsFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourBookingEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_TOUR_BOOKING),
    withLatestFrom(this.store.pipe(select(booking))),
    filter(([action, booking]) => {
      if (booking && Number(booking.id) === (action as tourActions.LoadTourBookings).payload) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourBooking) =>
      this.tourService.fetchTourBookingByID(action.payload).pipe(
        map(response => (
          new tourActions.LoadTourBookingSuccess(response)
        )),
        catchError((error) => of(
          new tourActions.LoadTourBookingFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourBookingEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.CREATE_TOUR_BOOKING),
    exhaustMap((action: tourActions.CreateTourBooking) =>
      this.tourService.createTourBooking(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            response.total_traveler_fee = 0;
            response.total_traveler_fee_after_saving = 0;
            response.passenger_count = 0;
            if (result.traveler_id) {
              response.traveler_id = result.traveler_id;
              response.passenger_count = 1;
            }
            return new tourActions.CreateTourBookingSuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourBookingFailed(error)))
        )
    )
  );
  @Effect()
  public createTourBookingSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.CREATE_TOUR_BOOKING_SUCCESS),
    map((action: tourActions.CreateTourBookingSuccess) => new tourActions.FetchTourBookingTotalFee(action.payload.id))
  );
  @Effect()
  public updateTourBookingByIdEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.UPDATE_TOUR_BOOKING),
    exhaustMap((action: tourActions.UpdateTourBooking) =>
      this.tourService.updateTourBooking(action.payload)
        .pipe(
          map(() => new tourActions.UpdateTourBookingSuccess(action.payload)),
          catchError(error => of(new tourActions.UpdateTourBookingFailed(error)))
        )
    )
  );
  @Effect()
  public removeTourBookingByIdEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.REMOVE_TOUR_BOOKING),
    switchMap((action: tourActions.RemoveTourBooking) =>
      this.tourService.removeTourBooking(action.payload)
        .pipe(
          map(() => new tourActions.RemoveTourBookingSuccess(action.payload)),
          catchError(error => of(new tourActions.RemoveTourBookingFailed(error)))
        )
    )
  );
  @Effect()
  public fetchTourBookingTotalFeeEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE),
    exhaustMap((action: tourActions.FetchTourBookingTotalFee) =>
      this.tourService.fetchTotalFeeForTraveler(action.payload).pipe(
        map(response => new tourActions.FetchTourBookingTotalFeeSuccess(response)),
        catchError((error) => of(
          new tourActions.FetchTourBookingTotalFeeFailed(error)
        ))
      )
    )
  );
  @Effect()
  public FetchTourBookingTotalFeeSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE_SUCCESS),
    withLatestFrom(this.store.pipe(select(booking))),
    filter(([_, booking]) => {
      if (
        booking &&
        ((booking.total_payments_made === booking.total_traveler_fee_after_saving && booking.payment_status_id !== 200) ||
          (booking.total_payments_made !== booking.total_traveler_fee_after_saving && booking.payment_status_id !== 100))
      ) {
        return true;
      }
      return false;
    }),
    map((action: any) => action[1]),
    map((tourBooking: any) => {
      let booking: ITourBooking = tourBooking;
      if (booking.total_payments_made === booking.total_traveler_fee_after_saving) {
        booking.payment_status_id = 200;
      } else {
        booking.payment_status_id = 100;
      }
      return new tourActions.UpdateTourBooking(booking);
    })
  );
  @Effect()
  public loadTourBookingPaymentModesEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES),
    withLatestFrom(this.store.pipe(select(paymentModes))),
    filter(([_, paymentModes]) => {
      if (paymentModes) { return false; } return true;
    }),
    exhaustMap(() =>
      this.tourService.fetchPaymentModes().pipe(
        map(response => new tourActions.LoadTourBookingPaymentModesSuccess(response)),
        catchError((error) => of(
          new tourActions.LoadTourBookingPaymentModesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public loadTourBookingPaymentHistoryByBookingEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING),
    withLatestFrom(this.store.pipe(select(paymentHistory))),
    filter(([action, paymentHistory]) => {
      if (
        paymentHistory &&
        paymentHistory.length &&
        Number(paymentHistory[0].booking_id) === (action as tourActions.LoadTourBookingPaymentHistoryByBooking).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourBookingPaymentHistoryByBooking) =>
      this.tourService.fetchPaymentsByBookingId(action.payload).pipe(
        map(response => new tourActions.LoadTourBookingPaymentHistoryByBookingSuccess(response)),
        catchError((error) => of(
          new tourActions.LoadTourBookingPaymentHistoryByBookingFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createPaymentEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.CREATE_PAYMENT),
    exhaustMap((action: tourActions.CreatePayment) =>
      this.tourService.createPayment(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            return new tourActions.CreatePaymentSuccess(response);
          }),
          catchError(error => of(new tourActions.CreatePaymentFailed(error)))
        )
    )
  );
  @Effect()
  public createPaymentSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourBookingActions.CREATE_PAYMENT_SUCCESS),
    withLatestFrom(this.store.pipe(select(selectedBookingForPayment))),
    filter(([_, selectedBookingForPayment]) => {
      if (
        selectedBookingForPayment &&
        selectedBookingForPayment.payment_status_id === 100 &&
        selectedBookingForPayment.total_payments_made >= selectedBookingForPayment.total_traveler_fee_after_saving
      ) {
        return true;
      }
      return false;
    }),
    map((action: any) => action[1]),
    map((tourBooking: ITourBooking) => {
      let booking: ITourBooking = tourBooking;
      booking.payment_status_id = 200;
      return new tourActions.UpdateTourBooking(booking);
    })
  );
  @Effect()
  public loadTourPassengersEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.LOAD_TOUR_PASSENGERS),
    withLatestFrom(this.store.pipe(select(passengers))),
    filter(([action, passengers]) => {
      if (
        passengers &&
        passengers[0] &&
        Number(passengers[0].booking_id) === (action as tourActions.LoadTourPassengers).payload
      ) {
        return false;
      }
      return true;
    }),
    map((action: any) => action[0]),
    exhaustMap((action: tourActions.LoadTourPassengers) =>
      this.tourService.fetchTourPassengersByBooking(action.payload).pipe(
        map(response => {
          if (response.length) {
            response[0].showPassengerDetails = true;
            response[0].showPersonalDetails = true;
            response[0].showPassportDetails = false;
            response[0].showPriceDetails = false;
          }
          return new tourActions.LoadTourPassengersSuccess(response);
        }),
        catchError((error) => of(
          new tourActions.LoadTourPassengersFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createTourPassengerEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.CREATE_TOUR_PASSENGER),
    exhaustMap((action: tourActions.CreateTourPassenger) =>
      this.tourService.createTourPassenger(action.payload)
        .pipe(
          map((result: any) => {
            const response = action.payload;
            response.id = result.insertedId;
            response.showPassengerDetails = true;
            response.showPersonalDetails = true;
            return new tourActions.CreateTourPassengerSuccess(response);
          }),
          catchError(error => of(new tourActions.CreateTourPassengerFailed(error)))
        )
    )
  );
  @Effect()
  public createTourBookingByIdSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.CREATE_TOUR_PASSENGER_SUCCESS),
    map((action: tourActions.CreateTourPassengerSuccess) => new tourActions.FetchTourBookingTotalFee(action.payload.booking_id))
  );
  @Effect()
  public updateTourPassengerByIdEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.UPDATE_TOUR_PASSENGER),
    exhaustMap((action: tourActions.UpdateTourPassenger) =>
      this.tourService.updateTourPassenger(action.payload)
        .pipe(
          map(() => {
            action.payload.showPassengerDetails = true;
            action.payload.showPersonalDetails = true;
            return new tourActions.UpdateTourPassengerSuccess(action.payload)
          }),
          catchError(error => of(new tourActions.UpdateTourPassengerFailed(error)))
        )
    )
  );
  @Effect()
  public updateTourBookingByIdSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.UPDATE_TOUR_PASSENGER_SUCCESS),
    map((action: tourActions.UpdateTourPassengerSuccess) => new tourActions.FetchTourBookingTotalFee(action.payload.booking_id))
  );
  @Effect()
  public removeTourPassengerByIdEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.REMOVE_TOUR_PASSENGER),
    switchMap((action: tourActions.RemoveTourPassenger) =>
      this.tourService.removeTourPassenger(action.payload.passengerId).pipe(
        map(() => new tourActions.RemoveTourPassengerSuccess(action.payload)),
        catchError(error => of(new tourActions.RemoveTourPassengerFailed(error)))
      )
    )
  );
  @Effect()
  public removeTourBookingByIdSuccessEffect = this.actions.pipe(
    ofType(tourActions.TourPassengerActions.REMOVE_TOUR_PASSENGER_SUCCESS),
    map((action: tourActions.RemoveTourPassengerSuccess) => new tourActions.FetchTourBookingTotalFee(action.payload.bookingId))
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public tourService: TourService
  ) {
  }

}

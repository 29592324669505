import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IProgram } from '../models/Program';

@Injectable({ providedIn: 'root' })
export class ProgramService {
  constructor(private http: HttpClient) {
  }

  /* Fetch programs */
  public fetchPrograms = (): Observable<IProgram[]> => {
    return this.http.get<IProgram[]>(`${environment.api_service}/api/tour/programs`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch program by ID */
  public fetchProgramByID = (programId: number): Observable<IProgram> => {
    return this.http.get<IProgram>(`${environment.api_service}/api/tour/program/${programId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new program */
  createProgram = (program: IProgram) => {
    return this.http.post<IProgram>(`${environment.api_service}/api/tour/programs`, program).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update program by id */
  updateProgram = (program: IProgram) => {
    return this.http.put<IProgram>(`${environment.api_service}/api/tour/program/${program.id}`, program).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove program by id */
  removeProgram = (programId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/tour/program/${programId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { ICompany } from 'src/app/core/models/Company';

export interface CompanyState {
  isProcessing: boolean;
  company: ICompany;
  companies: [ICompany];
  message: any;
}

export const initialState: CompanyState = {
  isProcessing: false,
  company: null,
  companies: null,
  message: null
};

export const initialFormState: ICompany = {
  id: null,
  company_code: '',
  name: '',
  address: ''
};

export const getApplicationState = (state: ApplicationState) => state;

// exports companyState from ApplicationState
export const companyState = createSelector(getApplicationState, (state: ApplicationState) => state.companyState);
// exports companies from ApplicationState
export const companies = createSelector(getApplicationState, (state: ApplicationState) => state.companyState.companies);
// exports company from ApplicationState
export const company = createSelector(getApplicationState, (state: ApplicationState) => state.companyState.company);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ComponentsRoutingModule } from './components-routing.module';

// Components
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { InfoMessageComponent } from './info-message/info-message.component';
import { RefreshButtonComponent } from './refresh-button/refresh-button.component';
import { CreateButtonComponent } from './create-button/create-button.component';
import { UpdateButtonComponent } from './update-button/update-button.component';
import { RemoveButtonComponent } from './remove-button/remove-button.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { LoadMoreButtonComponent } from './load-more-button/load-more-button.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { SidePanelHeaderComponent } from './side-panel-header/side-panel-header.component';
import { RemoveConfirmationComponent } from './remove-confirmation/remove-confirmation.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { InputValidationMessageComponent } from './input-validation-message/input-validation-message.component';

@NgModule({
  declarations: [
    LoadingMessageComponent,
    InfoMessageComponent,
    RefreshButtonComponent,
    CreateButtonComponent,
    UpdateButtonComponent,
    RemoveButtonComponent,
    SaveButtonComponent,
    CancelButtonComponent,
    LoadMoreButtonComponent,
    BackButtonComponent,
    AddButtonComponent,
    FilterButtonComponent,
    SidePanelHeaderComponent,
    RemoveConfirmationComponent,
    TextFieldComponent,
    TextAreaComponent,
    InputValidationMessageComponent
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule
  ],
  exports: [
    LoadingMessageComponent,
    InfoMessageComponent,
    RefreshButtonComponent,
    CreateButtonComponent,
    UpdateButtonComponent,
    RemoveButtonComponent,
    SaveButtonComponent,
    CancelButtonComponent,
    LoadMoreButtonComponent,
    BackButtonComponent,
    AddButtonComponent,
    FilterButtonComponent,
    SidePanelHeaderComponent,
    RemoveConfirmationComponent,
    TextFieldComponent,
    TextAreaComponent,
    InputValidationMessageComponent
  ]
})
export class ComponentsModule {
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericField]'
})

export class NumericMaskFieldDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;

    if (this.el.nativeElement.value) {
      this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
      if (initalValue !== this.el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }

}

import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { IProgram } from 'src/app/core/models/Program';

export interface ProgramState {
  isProcessing: boolean;
  program: IProgram;
  programs: [IProgram];
  message: any;
}

export const initialState: ProgramState = {
  isProcessing: false,
  program: null,
  programs: null,
  message: null
};

export const initialFormState: IProgram = {
  id: null,
  code: '',
  name: ''
};

export const getApplicationState = (state: ApplicationState) => state;

// exports programState from ApplicationState
export const programState = createSelector(getApplicationState, (state: ApplicationState) => state.programState);
// exports programs from ApplicationState
export const programs = createSelector(getApplicationState, (state: ApplicationState) => state.programState.programs);
// exports program from ApplicationState
export const program = createSelector(getApplicationState, (state: ApplicationState) => state.programState.program);

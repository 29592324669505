import * as commonActions from './common.actions';

export function commonReducer(state: any, action: commonActions.CommonAction): any {
  switch (action.type) {
    case commonActions.CommonActions.LOAD_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: action.payload
      };
    }
    case commonActions.CommonActions.LOAD_STATUSES_FAILED: {
      return {
        ...state,
        companies: null
      };
    }
    default: {
      return state;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ITheme } from '../models/Theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(private http: HttpClient) {
  }

  /* Fetch themes */
  public fetchThemes = (): Observable<ITheme[]> => {
    return this.http.get<ITheme[]>(`${environment.api_service}/api/theme`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch theme by ID */
  public fetchThemeByID = (themeId: number): Observable<ITheme> => {
    return this.http.get<ITheme>(`${environment.api_service}/api/theme/${themeId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new theme */
  createTheme = (theme: ITheme) => {
    return this.http.post<ITheme>(`${environment.api_service}/api/theme`, theme).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update theme by id */
  updateTheme = (theme: ITheme) => {
    return this.http.put<ITheme>(`${environment.api_service}/api/theme/${theme.id}`, theme).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove theme by id */
  removeTheme = (themeId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/theme/${themeId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

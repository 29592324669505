import * as directorActions from './director.actions';
import { IDirector } from 'src/app/core/models/Director';

export function directorReducer(state: any, action: directorActions.DirectorAction): any {
  switch (action.type) {
    case directorActions.DirectorActions.LOAD_DIRECTORS: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case directorActions.DirectorActions.LOAD_DIRECTORS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        directors: action.payload,
        message: null
      };
    }
    case directorActions.DirectorActions.LOAD_DIRECTORS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        directors: null,
        message: { status: 412, error: action.payload }
      };
    }
    case directorActions.DirectorActions.SET_DIRECTOR: {
      return {
        ...state,
        isProcessing: false,
        director: action.payload,
        message: null
      };
    }
    case directorActions.DirectorActions.SET_DIRECTORS: {
      return {
        ...state,
        isProcessing: false,
        directors: action.payload,
        message: null
      };
    }
    case directorActions.DirectorActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case directorActions.DirectorActions.CREATE_DIRECTOR: {
      return {
        ...state,
        isProcessing: true,
        director: null,
        message: null
      };
    }
    case directorActions.DirectorActions.CREATE_DIRECTOR_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        directors: [...state.directors, ...action.payload],
        message: { status: 201, info: 'Director created successfully' }
      };
    }
    case directorActions.DirectorActions.CREATE_DIRECTOR_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case directorActions.DirectorActions.UPDATE_DIRECTOR: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case directorActions.DirectorActions.UPDATE_DIRECTOR_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        director: null,
        directors: [
          ...state.directors.filter((director: IDirector) => director.id !== action.payload.id),
          ...action.payload
        ],
        message: {
          status: 200,
          info: 'Director updated successfully'
        }
      };
    }
    case directorActions.DirectorActions.UPDATE_DIRECTOR_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case directorActions.DirectorActions.REMOVE_DIRECTOR: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case directorActions.DirectorActions.REMOVE_DIRECTOR_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        director: null,
        directors: state.directors.filter((director: IDirector) => director.id !== action.payload),
        message: { status: 200, info: 'Director removed successfully' }
      };
    }
    case directorActions.DirectorActions.REMOVE_DIRECTOR_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    default: {
      return state;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-row',
  templateUrl: './list-row.component.html',
  styleUrls: ['./list-row.component.css']
})
export class ListRowComponent implements OnInit {
  @Input() listElement: any;
  @Input() listSubHeaderName: string;
  @Input() listSubHeaderIcon: string;
  @Input() listDate: string;

  constructor() {
  }

  ngOnInit() {
  }

}

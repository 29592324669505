import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.css']
})
export class RefreshButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() btnText: string;

  @Output() refresh = new EventEmitter();

  constructor() {
  }

  refreshEvent = () => {
    this.refresh.emit();
  };

  ngOnInit() {
  }

}

import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { ISubscription } from 'src/app/core/models/Subscription';

export interface SubscriptionState {
  isProcessing: boolean;
  subscriptions: [ISubscription];
  message: any;
}

export const initialState: SubscriptionState = {
  isProcessing: false,
  subscriptions: null,
  message: null
};

export const getApplicationState = (state: ApplicationState) => state;

// exports subscriptionState from ApplicationState
export const subscriptionState = createSelector(getApplicationState, (state: ApplicationState) => state.subscriptionState);
// exports subscriptions from ApplicationState
export const subscriptions = createSelector(getApplicationState, (state: ApplicationState) => state.subscriptionState.subscriptions);

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as subscriptionActions from './subscription.actions';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ApplicationState } from 'src/app/app.state';
import { subscriptions } from './subscription.state';

@Injectable()
export class SubscriptionEffects {
  @Effect()
  public loadSubscriptionsEffect = this.actions.pipe(
    ofType(subscriptionActions.SubscriptionActions.LOAD_SUBSCRIPTIONS),
    withLatestFrom(this.store.pipe(select(subscriptions))),
    filter(([_, subscriptions]) => {
      if (subscriptions) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.subscriptionService.fetchSubscription().pipe(
        map(response => (
          new subscriptionActions.LoadSubscriptionsSuccess(response)
        )),
        catchError((error) => of(
          new subscriptionActions.LoadSubscriptionsFailed(error)
        ))
      )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public subscriptionService: SubscriptionService
  ) {
  }

}

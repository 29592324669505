import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ApplicationState, initialState } from 'src/app/app.state';
import { Logout } from 'src/app/store/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = {
      user_id: sessionStorage.getItem('user_id'),
      company_id: sessionStorage.getItem('company_id'),
      token: sessionStorage.getItem('token')
    };
    if (currentUser && currentUser.user_id && currentUser.company_id && currentUser.token) {
      return true;
    }
    this.store.dispatch(new Logout(initialState));
    return false;
  }
}

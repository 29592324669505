import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import {ApplicationState, initialState} from 'src/app/app.state';
import {Logout} from 'src/app/store/user';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  company: string;

  constructor(public store: Store<ApplicationState>) {
  }

  logout = () => {
    this.store.dispatch(new Logout(initialState));
  };

  ngOnInit() {
    this.company = sessionStorage.getItem('company_name');
  }

}

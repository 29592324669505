import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as companyActions from './company.actions';
import { CompanyService } from 'src/app/core/services/company.service';
import { ApplicationState } from 'src/app/app.state';
import { companies } from './company.state';

@Injectable()
export class CompanyEffects {
  @Effect()
  public loadCompaniesEffect = this.actions.pipe(
    ofType(companyActions.CompanyActions.LOAD_COMPANIES),
    withLatestFrom(this.store.pipe(select(companies))),
    filter(([_, companies]) => {
      if (companies) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.companyService.fetchCompanies().pipe(
        map(response => (
          new companyActions.LoadCompaniesSuccess(response)
        )),
        catchError((error) => of(
          new companyActions.LoadCompaniesFailed(error)
        ))
      )
    )
  );
  @Effect()
  public createCompanyEffect = this.actions.pipe(
    ofType(companyActions.CompanyActions.CREATE_COMPANY),
    exhaustMap((action: companyActions.CreateCompany) =>
      this.companyService.createCompany(action.payload)
        .pipe(
          map(result => {
            const response = action.payload;
            response.id = result.insertedId;
            return new companyActions.CreateCompanySuccess(response);
          }),
          catchError(error => of(new companyActions.CreateCompanyFailed(error)))
        )
    )
  );
  @Effect()
  public updateCompanyByIdEffect = this.actions.pipe(
    ofType(companyActions.CompanyActions.UPDATE_COMPANY),
    exhaustMap((action: companyActions.UpdateCompany) =>
      this.companyService.updateCompany(action.payload)
        .pipe(
          map(() => new companyActions.UpdateCompanySuccess(action.payload)),
          catchError(error => of(new companyActions.UpdateCompanyFailed(error)))
        )
    )
  );
  @Effect()
  public removeCompanyByIdEffect = this.actions.pipe(
    ofType(companyActions.CompanyActions.REMOVE_COMPANY),
    switchMap((action: companyActions.RemoveCompany) =>
      this.companyService.removeCompany(action.payload)
        .pipe(
          map(() => new companyActions.RemoveCompanySuccess(action.payload)),
          catchError(error => of(new companyActions.RemoveCompanyFailed(error)))
        )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public companyService: CompanyService
  ) {
  }

}

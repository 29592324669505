import { Action } from '@ngrx/store';

import { ICompany } from 'src/app/core/models/Company';

export enum CompanyActions {
  LOAD_COMPANIES = '[Company] Load Companies',
  LOAD_COMPANIES_SUCCESS = '[Company] Load Companies Success',
  LOAD_COMPANIES_FAILED = '[Company] Load Companies Failed',

  SET_COMPANY = '[Company] Set Company',
  SET_COMPANIES = '[Company] Set Companies',
  SET_MESSAGE = '[Company] Set Message',

  CREATE_COMPANY = '[Company] Create Company',
  CREATE_COMPANY_SUCCESS = '[Company] Create Company Success',
  CREATE_COMPANY_FAILED = '[Company] Create Company Failed',

  UPDATE_COMPANY = '[Company] Update Company',
  UPDATE_COMPANY_SUCCESS = '[Company] Update Company Success',
  UPDATE_COMPANY_FAILED = '[Company] Update Company Failed',

  REMOVE_COMPANY = '[Company] Remove Company',
  REMOVE_COMPANY_SUCCESS = '[Company] Remove Company Success',
  REMOVE_COMPANY_FAILED = '[Company] Remove Company Failed'
}

export class LoadCompanies implements Action {
  readonly type: string = CompanyActions.LOAD_COMPANIES;

  constructor(public payload: any) {
  }
}

export class LoadCompaniesSuccess implements Action {
  readonly type: string = CompanyActions.LOAD_COMPANIES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadCompaniesFailed implements Action {
  readonly type: string = CompanyActions.LOAD_COMPANIES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetCompany implements Action {
  readonly type: string = CompanyActions.SET_COMPANY;

  constructor(public payload: any) {
  }
}

export class SetCompanies implements Action {
  readonly type: string = CompanyActions.SET_COMPANIES;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = CompanyActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateCompany implements Action {
  readonly type: string = CompanyActions.CREATE_COMPANY;

  constructor(public payload: ICompany) {
  }
}

export class CreateCompanySuccess implements Action {
  readonly type: string = CompanyActions.CREATE_COMPANY_SUCCESS;

  constructor(public payload: ICompany) {
  }
}

export class CreateCompanyFailed implements Action {
  readonly type: string = CompanyActions.CREATE_COMPANY_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateCompany implements Action {
  readonly type: string = CompanyActions.UPDATE_COMPANY;

  constructor(public payload: ICompany) {
  }
}

export class UpdateCompanySuccess implements Action {
  readonly type: string = CompanyActions.UPDATE_COMPANY_SUCCESS;

  constructor(public payload: ICompany) {
  }
}

export class UpdateCompanyFailed implements Action {
  readonly type: string = CompanyActions.UPDATE_COMPANY_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveCompany implements Action {
  readonly type: string = CompanyActions.REMOVE_COMPANY;

  constructor(public payload: number) {
  }
}

export class RemoveCompanySuccess implements Action {
  readonly type: string = CompanyActions.REMOVE_COMPANY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveCompanyFailed implements Action {
  readonly type: string = CompanyActions.REMOVE_COMPANY_FAILED;

  constructor(public payload: any) {
  }
}

export type CompanyAction =
  LoadCompanies | LoadCompaniesSuccess | LoadCompaniesFailed |
  SetCompany | SetCompanies | SetMessage |
  CreateCompany | CreateCompanySuccess | CreateCompanyFailed |
  UpdateCompany | UpdateCompanySuccess | UpdateCompanyFailed |
  RemoveCompany | RemoveCompanySuccess | RemoveCompanyFailed 

import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { IDirector } from 'src/app/core/models/Director';

export interface DirectorState {
  isProcessing: boolean;
  director: IDirector;
  directors: [IDirector];
  message: any;
}

export const initialState: DirectorState = {
  isProcessing: false,
  director: null,
  directors: null,
  message: null
};

export const initialFormState: IDirector = {
  id: null,
  code: '',
  status: 100,
  firstname: '',
  lastname: '',
  description: ''
};

export const getApplicationState = (state: ApplicationState) => state;

// exports directorState from ApplicationState
export const directorState = createSelector(getApplicationState, (state: ApplicationState) => state.directorState);
// exports directors from ApplicationState
export const directors = createSelector(getApplicationState, (state: ApplicationState) => state.directorState.directors);
// exports director from ApplicationState
export const director = createSelector(getApplicationState, (state: ApplicationState) => state.directorState.director);

import * as programActions from './program.actions';
import { IProgram } from 'src/app/core/models/Program';

export function programReducer(state: any, action: programActions.ProgramAction): any {
  switch (action.type) {
    case programActions.ProgramActions.LOAD_PROGRAMS: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case programActions.ProgramActions.LOAD_PROGRAMS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        programs: action.payload,
        message: null
      };
    }
    case programActions.ProgramActions.LOAD_PROGRAMS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        programs: null,
        message: { status: 412, error: action.payload }
      };
    }
    case programActions.ProgramActions.SET_PROGRAM: {
      return {
        ...state,
        isProcessing: false,
        program: action.payload,
        message: null
      };
    }
    case programActions.ProgramActions.SET_PROGRAMS: {
      return {
        ...state,
        isProcessing: false,
        programs: action.payload,
        message: null
      };
    }
    case programActions.ProgramActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case programActions.ProgramActions.CREATE_PROGRAM: {
      return {
        ...state,
        isProcessing: true,
        program: null,
        message: null
      };
    }
    case programActions.ProgramActions.CREATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        programs: [...state.programs, ...action.payload],
        message: { status: 201, info: 'Program created successfully' }
      };
    }
    case programActions.ProgramActions.CREATE_PROGRAM_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case programActions.ProgramActions.UPDATE_PROGRAM: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case programActions.ProgramActions.UPDATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        program: null,
        programs: [
          ...state.programs.filter((program: IProgram) => program.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Program updated successfully' }
      };
    }
    case programActions.ProgramActions.UPDATE_PROGRAM_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case programActions.ProgramActions.REMOVE_PROGRAM: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case programActions.ProgramActions.REMOVE_PROGRAM_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        program: null,
        programs: state.programs.filter((program: IProgram) => program.id !== action.payload),
        message: { status: 200, info: 'Program removed successfully' }
      };
    }
    case programActions.ProgramActions.REMOVE_PROGRAM_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    default: {
      return state;
    }
  }
}

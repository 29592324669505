import { ActionReducerMap } from '@ngrx/store';

import { ApplicationState } from './app.state';

import * as userStore from './store/user';
import * as commonStore from './store/common';
import * as enquiryStore from './store/enquiry';
import * as subscriptionStore from './store/subscription';
import * as companyStore from './store/company';
import * as programStore from './store/program';
import * as geographicCodeStore from './store/geographic.code';
import * as themeStore from './store/theme';
import * as directorStore from './store/director';
import * as tourStore from './store/tour';

export const reducers: ActionReducerMap<ApplicationState> = {
  user: userStore.userReducer,
  commonState: commonStore.commonReducer,
  enquiryState: enquiryStore.enquiryReducer,
  subscriptionState: subscriptionStore.subscriptionReducer,
  companyState: companyStore.companyReducer,
  programState: programStore.programReducer,
  geographicCodeState: geographicCodeStore.geographicCodeReducer,
  themeState: themeStore.themeReducer,
  directorState: directorStore.directorReducer,
  tourState: tourStore.tourReducer,
};

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-section',
  templateUrl: './message-section.component.html',
  styleUrls: ['./message-section.component.css']
})
export class MessageSectionComponent implements OnInit {
  @Input() status: number;
  @Input() data: string;

  constructor() {
  }

  ngOnInit() {
  }

}

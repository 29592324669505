import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {ICompany} from '../models/Company';

@Injectable({providedIn: 'root'})
export class CompanyService {
  constructor(private http: HttpClient) {
  }

  /* Fetch companies */
  public fetchCompanies = (): Observable<[ICompany]> => {
    return this.http.get<any>(`${environment.api_service}/api/company`).pipe(
      map((response: any) => {
        return response.data || response || [];
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch company by ID */
  public fetchCompanyByID = (companyId: number): Observable<ICompany> => {
    return this.http.get<ICompany>(`${environment.api_service}/api/company/${companyId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new company */
  createCompany = (company: ICompany) => {
    return this.http.post<ICompany>(`${environment.api_service}/api/company`, company).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update company by id */
  updateCompany = (company: ICompany) => {
    return this.http.put<ICompany>(`${environment.api_service}/api/company/${company.id}`, company).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove company by id */
  removeCompany = (companyId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/company/${companyId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ITourBooking } from "src/app/core/models/Tour";

@Component({
  selector: 'app-remove-confirmation',
  templateUrl: './remove-confirmation.component.html',
  styleUrls: ['./remove-confirmation.component.css']
})
export class RemoveConfirmationComponent implements OnInit {
  @Input() entityName: string;
  @Input() message: string;
  @Input() classString: string;
  @Input() disabled: boolean;
  @Input() showMessage: boolean;

  @Output() removeConfirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor() {
  }

  removeEvent = () => {
    this.removeConfirm.emit();
  };

  cancelEvent = () => {
    this.cancel.emit();
  };

  ngOnInit() {
  }

}

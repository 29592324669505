import {createSelector} from '@ngrx/store';

import {ApplicationState} from 'src/app/app.state';

export interface CommonState {
  statuses: { code: number, name: string, short_name: string };
}

export const initialState: CommonState = {
  statuses: null
};

export const getApplicationState = (state: ApplicationState) => state;

// exports commonState from ApplicationState
export const commonState = createSelector(getApplicationState, (state: ApplicationState) => state.commonState);

// exports statuses from ApplicationState.commonState
export const statuses = createSelector(getApplicationState, (state: ApplicationState) => state.commonState.statuses);

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as enquiryActions from './enquiry.actions';
import { EnquiriesService } from 'src/app/core/services/enquiry.service';
import { ApplicationState } from 'src/app/app.state';
import { enquiries } from './enquiry.state';

@Injectable()
export class EnquiryEffects {
  @Effect()
  public loadEnquiriesEffect = this.actions.pipe(
    ofType(enquiryActions.EnquiryActions.LOAD_ENQUIRIES),
    withLatestFrom(this.store.pipe(select(enquiries))),
    filter(([_, enquiries]) => {
      if (enquiries) {
        return false;
      }
      return true;
    }),
    exhaustMap(() =>
      this.enquiryService.fetchEnquiries().pipe(
        map(response => (
          new enquiryActions.LoadEnquiriesSuccess(response)
        )),
        catchError((error) => of(
          new enquiryActions.LoadEnquiriesFailed(error)
        ))
      )
    )
  );

  constructor(
    public actions: Actions,
    public router: Router,
    public store: Store<ApplicationState>,
    public enquiryService: EnquiriesService
  ) {
  }

}

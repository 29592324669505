import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input-validation-message',
  templateUrl: './input-validation-message.component.html',
  styleUrls: ['./input-validation-message.component.css']
})
export class InputValidationMessageComponent implements OnInit {
  @Input() validationMessage: string;

  constructor() {
  }

  ngOnInit() {
  }

}

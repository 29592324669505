import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @Input() list: any;
  @Input() listSubHeaderName: string;
  @Input() listSubHeaderIcon: string;
  @Input() listDate: string;
  @Input() orderByField: string;

  constructor() { }

  ngOnInit() {
  }

}

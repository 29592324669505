import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.endsWith('/auth')) {
      req = req.clone({
        setHeaders: {
          'x-access-token': `${JSON.parse(sessionStorage.getItem('token'))}`
        }
      });
    }
    return next.handle(req);
  }
}

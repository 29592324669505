import {createSelector} from '@ngrx/store';

import {ApplicationState} from 'src/app/app.state';
import {IGeographicCode} from 'src/app/core/models/GeographicCode';

export interface GeographicCodeState {
  isProcessing: boolean;
  geographicCode: IGeographicCode;
  geographicCodes: [IGeographicCode];
  message: any;
}

export const initialState: GeographicCodeState = {
  isProcessing: false,
  geographicCode: null,
  geographicCodes: null,
  message: null
};

export const initialFormState: IGeographicCode = {
  id: null,
  geographic_code: '',
  description: ''
};

export const getApplicationState = (state: ApplicationState) => state;

// exports geographicCodeState from ApplicationState
export const geographicCodeState = createSelector(getApplicationState, (state: ApplicationState) => state.geographicCodeState);
// exports geographicCodes from ApplicationState
export const geographicCodes = createSelector(getApplicationState, (state: ApplicationState) => state.geographicCodeState.geographicCodes);
// exports geographicCode from ApplicationState
export const geographicCode = createSelector(getApplicationState, (state: ApplicationState) => state.geographicCodeState.geographicCode);

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.css']
})
export class LoadMoreButtonComponent implements OnInit {
  @Input() currentRecordCount: number;
  @Input() recordCount: number;
  @Input() isProcessing: boolean;

  @Output() loadMore = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  loadMoreEvent = () => {
    this.loadMore.emit();
  };

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IUser } from '../models/User';
import { IUserCompany } from '../models/UserCompany';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  public getUser() {
    return localStorage.getItem('user');
  }

  public authenticate(username: string, password: string): Observable<IUser> {
    return this.http.post<any>(`${environment.api_service}/auth`, { username, password })
      .pipe(
        catchError(this.handleError)
      );
  }

  public logout(): Observable<boolean> {
    return this.http.get<any>(`${environment.api_service}/api/user/logout`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* Fetch companies by userId */
  public fetchCompaniesForUser = (userId: number): Observable<[IUserCompany]> => {
    return this.http.get<any>(`${environment.api_service}/api/company/user/${userId}`).pipe(
      map((response: any) => {
        return response.data || response || [];
      }),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

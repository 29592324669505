import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import { ITheme } from 'src/app/core/models/Theme';

export interface ThemeState {
  isProcessing: boolean;
  theme: ITheme;
  themes: [ITheme];
  message: any;
}

export const initialState: ThemeState = {
  isProcessing: false,
  theme: null,
  themes: null,
  message: null
};

export const initialFormState: ITheme = {
  id: null,
  code: '',
  theme: ''
};

export const getApplicationState = (state: ApplicationState) => state;

// exports themeState from ApplicationState
export const themeState = createSelector(getApplicationState, (state: ApplicationState) => state.themeState);
// exports themes from ApplicationState
export const themes = createSelector(getApplicationState, (state: ApplicationState) => state.themeState.themes);
// exports theme from ApplicationState
export const theme = createSelector(getApplicationState, (state: ApplicationState) => state.themeState.theme);

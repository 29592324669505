import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as userActions from './user.actions';
import {UserService} from 'src/app/core/services/user.service';

@Injectable()
export class UserEffects {
  @Effect()
  public loginEffect = this.actions.pipe(
    ofType(userActions.LoginActions.LOGIN),
    switchMap(
      (action: userActions.Login) => {
        return this.userService.authenticate(action.payload.username, action.payload.password)
          .pipe(
            map(user => {
              return (new userActions.LoginSuccess(user));
            }),
            catchError((error) => {
              return of(new userActions.LoginFailed(error));
            })
          );
      }
    )
  );
  @Effect()
  public loginSuccessEffect = this.actions.pipe(
    ofType(userActions.LoginActions.LOGIN_SUCCESS),
    map((action: any) => action.payload),
    map((user) => {
      sessionStorage.setItem('token', JSON.stringify(user.token));
      sessionStorage.setItem('user_id', JSON.stringify(user.user_id));
      if (user && user.token && user.user_id) {
        return (new userActions.LoadCompanies(user.user_id));
      } else {
        return of(new userActions.LoginFailed({}));
      }
    })
  );
  @Effect()
  public logoutEffect = this.actions.pipe(
    ofType(userActions.LoginActions.LOGOUT),
    map(() => {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
      return (new userActions.LogoutSuccess({}));
    })
  );
  @Effect()
  public loadCompaniesEffect = this.actions.pipe(
    ofType(userActions.LoginActions.LOAD_COMPANIES),
    mergeMap((action: any) =>
      this.userService.fetchCompaniesForUser(action.payload).pipe(
        map(response => (
          new userActions.LoadCompaniesSuccess(response)
        )),
        catchError(() => of(
          new userActions.LoadCompaniesFailed({})
        ))
      )
    )
  );
  @Effect()
  public setCompanyEffect = this.actions.pipe(
    ofType(userActions.PersonalizeCompanyActions.SET_COMPANY),
    map((action: any) => action.payload),
    map((company) => {
      sessionStorage.setItem('company_id', company.company_id);
      sessionStorage.setItem('company_name', company.company_name);
      this.router.navigate(['/tour']);
      return new userActions.SetCompanySuccess(company.company_id);
    })
  );

  constructor(public actions: Actions, public router: Router, public userService: UserService) {
  }
}

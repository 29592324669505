import { Action } from '@ngrx/store';

import { IDirector } from 'src/app/core/models/Director';

export enum DirectorActions {
  LOAD_DIRECTORS = '[Director] Load Directors',
  LOAD_DIRECTORS_SUCCESS = '[Director] Load Directors Success',
  LOAD_DIRECTORS_FAILED = '[Director] Load Directors Failed',

  SET_DIRECTOR = '[Director] Set Director',
  SET_DIRECTORS = '[Director] Set Directors',
  SET_MESSAGE = '[Director] Set Message',

  CREATE_DIRECTOR = '[Director] Create Director',
  CREATE_DIRECTOR_SUCCESS = '[Director] Create Director Success',
  CREATE_DIRECTOR_FAILED = '[Director] Create Director Failed',

  UPDATE_DIRECTOR = '[Director] Update Director',
  UPDATE_DIRECTOR_SUCCESS = '[Director] Update Director Success',
  UPDATE_DIRECTOR_FAILED = '[Director] Update Director Failed',

  REMOVE_DIRECTOR = '[Director] Remove Director',
  REMOVE_DIRECTOR_SUCCESS = '[Director] Remove Director Success',
  REMOVE_DIRECTOR_FAILED = '[Director] Remove Director Failed'
}

export class LoadDirectors implements Action {
  readonly type: string = DirectorActions.LOAD_DIRECTORS;

  constructor(public payload: any) {
  }
}

export class LoadDirectorsSuccess implements Action {
  readonly type: string = DirectorActions.LOAD_DIRECTORS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadDirectorsFailed implements Action {
  readonly type: string = DirectorActions.LOAD_DIRECTORS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetDirector implements Action {
  readonly type: string = DirectorActions.SET_DIRECTOR;

  constructor(public payload: any) {
  }
}

export class SetDirectors implements Action {
  readonly type: string = DirectorActions.SET_DIRECTORS;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = DirectorActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateDirector implements Action {
  readonly type: string = DirectorActions.CREATE_DIRECTOR;

  constructor(public payload: IDirector) {
  }
}

export class CreateDirectorSuccess implements Action {
  readonly type: string = DirectorActions.CREATE_DIRECTOR_SUCCESS;

  constructor(public payload: IDirector) {
  }
}

export class CreateDirectorFailed implements Action {
  readonly type: string = DirectorActions.CREATE_DIRECTOR_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateDirector implements Action {
  readonly type: string = DirectorActions.UPDATE_DIRECTOR;

  constructor(public payload: IDirector) {
  }
}

export class UpdateDirectorSuccess implements Action {
  readonly type: string = DirectorActions.UPDATE_DIRECTOR_SUCCESS;

  constructor(public payload: IDirector) {
  }
}

export class UpdateDirectorFailed implements Action {
  readonly type: string = DirectorActions.UPDATE_DIRECTOR_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveDirector implements Action {
  readonly type: string = DirectorActions.REMOVE_DIRECTOR;

  constructor(public payload: number) {
  }
}

export class RemoveDirectorSuccess implements Action {
  readonly type: string = DirectorActions.REMOVE_DIRECTOR_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveDirectorFailed implements Action {
  readonly type: string = DirectorActions.REMOVE_DIRECTOR_FAILED;

  constructor(public payload: any) {
  }
}

export type DirectorAction =
  LoadDirectors | LoadDirectorsSuccess | LoadDirectorsFailed |
  SetDirector | SetDirectors | SetMessage |
  CreateDirector | CreateDirectorSuccess | CreateDirectorFailed |
  UpdateDirector | UpdateDirectorSuccess | UpdateDirectorFailed |
  RemoveDirector | RemoveDirectorSuccess | RemoveDirectorFailed 

import { createSelector } from '@ngrx/store';

import { ApplicationState } from 'src/app/app.state';
import {
  IBookingStatuses,
  IPaymentStatuses,
  ITour,
  ITourAddon,
  ITourAddonPriceCategory,
  ITourBooking,
  ITourDiscount,
  ITourItinerary,
  ITourPassenger,
  ITourPriceCategory,
  IPaymentMode,
  IPayment
} from 'src/app/core/models/Tour';

export interface TourState {
  isProcessing: boolean;
  pagination: { page: number, limit: number }
  tour: ITour;
  tours: ITour[];
  discount: ITourDiscount;
  discounts: ITourDiscount[];
  offers: ITourDiscount[];
  addon: ITourAddon;
  addons: ITourAddon[];
  priceCategory: ITourPriceCategory;
  priceCategories: ITourPriceCategory[];
  addonsForPriceCategory: { double: ITourAddonPriceCategory[], single: ITourAddonPriceCategory[] };
  itinerary: ITourItinerary,
  itineraries: ITourItinerary[],
  bookingStatuses: IBookingStatuses[],
  paymentStatuses: IPaymentStatuses[],
  booking: ITourBooking,
  bookings: ITourBooking[],
  passenger: ITourPassenger,
  passengers: ITourPassenger[],
  paymentModes: IPaymentMode[],
  paymentHistory: IPayment[],
  selectedBookingForPayment: ITourBooking;
  message: any;
}

export const initialState: TourState = {
  isProcessing: false,
  pagination: { page: 1, limit: 50 },
  tour: null,
  tours: null,
  discount: null,
  discounts: null,
  offers: null,
  addon: null,
  addons: null,
  priceCategory: null,
  priceCategories: null,
  addonsForPriceCategory: null,
  itinerary: null,
  itineraries: null,
  bookingStatuses: null,
  paymentStatuses: null,
  booking: null,
  bookings: null,
  passenger: null,
  passengers: null,
  paymentModes: null,
  paymentHistory: null,
  selectedBookingForPayment: null,
  message: null
};

export const initialTourFormState: ITour = {
  id: null,
  program_code: null,
  status_id: 100,
  company_id: null,
  geographic_code_id: null,
  theme_id: null,
  name: '',
  description: '',
  content: '',
  director_id: null,
  tour_start_date: '',
  tour_end_date: '',
  number_of_nights: null,
  number_of_days: null,
  is_ship_included: false,
  total_pax_count_allowed: null,
  total_pax_count_waitlist: null,
  available_to_public: false
};

export const initialDiscountFormState: ITourDiscount = {
  id: null,
  tour_id: null,
  saving_name: '',
  from_date: '',
  to_date: '',
  saving_type: '$',
  saving_value: null
};

export const initialAddonFormState: ITourAddon = {
  id: null,
  tour_id: null,
  add_ons_name: '',
  price: null,
};

export const initialPriceCategoryFormState: ITourPriceCategory = {
  id: null,
  tour_id: null,
  category_name: '',
  double_occupancy_price: null,
  single_occupancy_price: null
};

export const initialItineraryFormState: ITourItinerary = {
  id: null,
  status_id: 100,
  tour_id: null,
  day_number: null,
  day_sequence: '',
  description: '',
  accommodation_included: false,
  accommodation_id: null,
  break_fast_included: false,
  lunch_included: false,
  dinner_included: false
};

export const initialBookingFormState: ITourBooking = {
  id: null,
  booking_status_id: 100,
  payment_status_id: 100,
  company_id: null,
  tour_id: null,
  traveler_id: null,
  number_of_pax_in_booking: null,
  booked_by_last_name: '',
  booked_by_first_name: '',
  booked_by_middle_name: '',
  date_of_birth: null,
  email_id: '',
  phone_number: null,
  address: '',
  city: '',
  state: '',
  country: '',
  postal_code_or_zip: '',
  total_payments_made: null,
  is_passenger: true,
  price_category_id: null,
  price_category_name: '',
  price_category_type: '',
  category_price: null,
  saving_id: null,
  saving_name: '',
  saving_value: null,
  price_after_saving: null,
  add_ons: []
};

export const initialPassengerFormState: ITourPassenger = {
  id: null,
  company_id: null,
  booking_id: null,
  last_name: '',
  first_name: '',
  middle_name: '',
  date_of_birth: '',
  email_id: '',
  phone_number: null,
  passport: '',
  passport_issue_date: '',
  passport_expiry_date: '',
  passport_issuer: '',
  badge_name: '',
  visa_required_flag: false,
  traveler_doc_flag: false,
  traveler_doc_sent_flag: false,
  address: '',
  city: '',
  state: '',
  country: '',
  postal_code_or_zip: '',
  price_category_id: null,
  price_category_name: '',
  price_category_type: '',
  category_price: null,
  saving_id: null,
  saving_name: '',
  saving_value: null,
  price_after_saving: null,
  add_ons: []
};

export const getApplicationState = (state: ApplicationState) => state;

// exports tourState from ApplicationState
export const tourState = createSelector(getApplicationState, (state: ApplicationState) => state.tourState);

// exports tour from ApplicationState.tourState
export const tour = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.tour);
// exports tours from ApplicationState.tourState
export const tours = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.tours);
// exports pagination from ApplicationState.tourState
export const pagination = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.pagination);

// exports discounts from ApplicationState.tourState
export const discounts = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.discounts);

// exports offers from ApplicationState.tourState
export const offers = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.offers);

// exports addons from ApplicationState.tourState
export const addons = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.addons);

// exports priceCategories from ApplicationState.tourState
export const priceCategories = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.priceCategories);
// exports addonsForPriceCategory from ApplicationState.tourState
export const addonsForPriceCategory = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.addonsForPriceCategory);

// exports itineraries from ApplicationState.tourState
export const itineraries = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.itineraries);

// exports bookingStatuses from ApplicationState.tourState
export const bookingStatuses = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.bookingStatuses);

// exports paymentStatuses from ApplicationState.tourState
export const paymentStatuses = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.paymentStatuses);

// exports booking from ApplicationState.tourState
export const booking = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.booking);

// exports bookings from ApplicationState.tourState
export const bookings = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.bookings);

// exports passengers from ApplicationState.tourState
export const passengers = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.passengers);

// exports paymentModes from ApplicationState.tourState
export const paymentModes = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.paymentModes);

// exports paymentHistory from ApplicationState.tourState
export const paymentHistory = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.paymentHistory);

// exports selectedBookingForPayment from ApplicationState.tourState
export const selectedBookingForPayment = createSelector(getApplicationState, (state: ApplicationState) => state.tourState.selectedBookingForPayment);

import {Action} from '@ngrx/store';

export enum CommonActions {
  LOAD_STATUSES = '[Common] Load Statuses',
  LOAD_STATUSES_SUCCESS = '[Common] Load Statuses Success',
  LOAD_STATUSES_FAILED = '[Common] Load Statuses Failed'
}

export class LoadStatuses implements Action {
  readonly type: string = CommonActions.LOAD_STATUSES;

  constructor(public payload: any) {
  }
}

export class LoadStatusesSuccess implements Action {
  readonly type: string = CommonActions.LOAD_STATUSES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadStatusesFailed implements Action {
  readonly type: string = CommonActions.LOAD_STATUSES_FAILED;

  constructor(public payload: any) {
  }
}

export type CommonAction =
  LoadStatuses | LoadStatusesSuccess | LoadStatusesFailed

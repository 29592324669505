import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-settings-list-row',
  templateUrl: './settings-list-row.component.html',
  styleUrls: ['./settings-list-row.component.css']
})
export class SettingsListRowComponent implements OnInit {
  listArray: { [id: string]: any };
  @Input() listElement: { [id: string]: any };

  constructor() {
  }

  ngOnInit() {
    this.listArray = Object.keys(this.listElement).map(key => (
      {field: key.replace('_', ' '), value: this.listElement[key]}
    ));
  }

}

import {Action} from '@ngrx/store';

export enum LoginActions {
  LoadShipss = '[Ships] Load Shipss',
  LOGIN = '[User] Login',
  LOGIN_SUCCESS = '[User] Login Success',
  LOGIN_FAILED = '[User] Login Failed',
  LOAD_COMPANIES = '[User] Load Companies',
  LOAD_COMPANIES_SUCCESS = '[User] Load Companies Success',
  LOAD_COMPANIES_FAILED = '[User] Load Companies Failed',
  LOGOUT = '[User] Logout',
  LOGOUT_SUCCESS = '[User] Logout Success'
}

export enum PersonalizeCompanyActions {
  SET_COMPANY = '[User] Set Company',
  SET_COMPANY_SUCCESS = '[User] Set Company Success'
}

export class Login implements Action {
  readonly type: string = LoginActions.LOGIN;

  constructor(public payload: any) {
  }
}

export class LoginSuccess implements Action {
  readonly type: string = LoginActions.LOGIN_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoginFailed implements Action {
  readonly type: string = LoginActions.LOGIN_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadCompanies implements Action {
  readonly type: string = LoginActions.LOAD_COMPANIES;

  constructor(public payload: any) {
  }
}

export class LoadCompaniesSuccess implements Action {
  readonly type: string = LoginActions.LOAD_COMPANIES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadCompaniesFailed implements Action {
  readonly type: string = LoginActions.LOAD_COMPANIES_FAILED;

  constructor(public payload: any) {
  }
}

export class Logout implements Action {
  readonly type: string = LoginActions.LOGOUT;

  constructor(public payload: any) {
  }
}

export class LogoutSuccess implements Action {
  readonly type: string = LoginActions.LOGOUT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetCompany implements Action {
  readonly type: string = PersonalizeCompanyActions.SET_COMPANY;

  constructor(public payload: any) {
  }
}

export class SetCompanySuccess implements Action {
  readonly type: string = PersonalizeCompanyActions.SET_COMPANY_SUCCESS;

  constructor(public payload: any) {
  }
}

export type LoginAction =
  Login | LoginSuccess | LoginFailed |
  LoadCompanies | LoadCompaniesSuccess | LoadCompaniesFailed |
  Logout |
  SetCompany | SetCompanySuccess

import { Action } from '@ngrx/store';

import { IProgram } from 'src/app/core/models/Program';

export enum ProgramActions {
  LOAD_PROGRAMS = '[Program] Load Programs',
  LOAD_PROGRAMS_SUCCESS = '[Program] Load Programs Success',
  LOAD_PROGRAMS_FAILED = '[Program] Load Programs Failed',

  SET_PROGRAM = '[Program] Set Program',
  SET_PROGRAMS = '[Program] Set Programs',
  SET_MESSAGE = '[Program] Set Message',

  CREATE_PROGRAM = '[Program] Create Program',
  CREATE_PROGRAM_SUCCESS = '[Program] Create Program Success',
  CREATE_PROGRAM_FAILED = '[Program] Create Program Failed',

  UPDATE_PROGRAM = '[Program] Update Program',
  UPDATE_PROGRAM_SUCCESS = '[Program] Update Program Success',
  UPDATE_PROGRAM_FAILED = '[Program] Update Program Failed',

  REMOVE_PROGRAM = '[Program] Remove Program',
  REMOVE_PROGRAM_SUCCESS = '[Program] Remove Program Success',
  REMOVE_PROGRAM_FAILED = '[Program] Remove Program Failed'
}

export class LoadPrograms implements Action {
  readonly type: string = ProgramActions.LOAD_PROGRAMS;

  constructor(public payload: any) {
  }
}

export class LoadProgramsSuccess implements Action {
  readonly type: string = ProgramActions.LOAD_PROGRAMS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadProgramsFailed implements Action {
  readonly type: string = ProgramActions.LOAD_PROGRAMS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetProgram implements Action {
  readonly type: string = ProgramActions.SET_PROGRAM;

  constructor(public payload: any) {
  }
}

export class SetPrograms implements Action {
  readonly type: string = ProgramActions.SET_PROGRAMS;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = ProgramActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateProgram implements Action {
  readonly type: string = ProgramActions.CREATE_PROGRAM;

  constructor(public payload: IProgram) {
  }
}

export class CreateProgramSuccess implements Action {
  readonly type: string = ProgramActions.CREATE_PROGRAM_SUCCESS;

  constructor(public payload: IProgram) {
  }
}

export class CreateProgramFailed implements Action {
  readonly type: string = ProgramActions.CREATE_PROGRAM_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateProgram implements Action {
  readonly type: string = ProgramActions.UPDATE_PROGRAM;

  constructor(public payload: IProgram) {
  }
}

export class UpdateProgramSuccess implements Action {
  readonly type: string = ProgramActions.UPDATE_PROGRAM_SUCCESS;

  constructor(public payload: IProgram) {
  }
}

export class UpdateProgramFailed implements Action {
  readonly type: string = ProgramActions.UPDATE_PROGRAM_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveProgram implements Action {
  readonly type: string = ProgramActions.REMOVE_PROGRAM;

  constructor(public payload: number) {
  }
}

export class RemoveProgramSuccess implements Action {
  readonly type: string = ProgramActions.REMOVE_PROGRAM_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveProgramFailed implements Action {
  readonly type: string = ProgramActions.REMOVE_PROGRAM_FAILED;

  constructor(public payload: any) {
  }
}

export type ProgramAction =
  LoadPrograms | LoadProgramsSuccess | LoadProgramsFailed |
  SetProgram | SetPrograms | SetMessage |
  CreateProgram | CreateProgramSuccess | CreateProgramFailed |
  UpdateProgram | UpdateProgramSuccess | UpdateProgramFailed |
  RemoveProgram | RemoveProgramSuccess | RemoveProgramFailed 

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.css']
})
export class RemoveButtonComponent implements OnInit {
  @Input() btnText: string;
  @Input() disabled: boolean;

  @Output() remove = new EventEmitter();

  constructor() {
  }

  removeEvent = () => {
    this.remove.emit();
  };

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IDirector } from '../models/Director';

@Injectable({ providedIn: 'root' })
export class DirectorService {
  constructor(private http: HttpClient) {
  }

  /* Fetch directors */
  public fetchDirectors = (): Observable<IDirector[]> => {
    return this.http.get<IDirector[]>(`${environment.api_service}/api/director`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch director by ID */
  public fetchDirectorByID = (directorId: number): Observable<IDirector> => {
    return this.http.get<IDirector>(`${environment.api_service}/api/director/${directorId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new director */
  createDirector = (director: IDirector) => {
    return this.http.post<IDirector>(`${environment.api_service}/api/director`, director).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update director by id */
  updateDirector = (director: IDirector) => {
    return this.http.put<IDirector>(`${environment.api_service}/api/director/${director.id}`, director).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove director by id */
  removeDirector = (directorId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/director/${directorId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

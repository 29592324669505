import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {Login} from 'src/app/store/user';
import {ApplicationState} from 'src/app/app.state';
import {Userstate, userState} from 'src/app/store/user/user.state';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  authenticationState: Observable<Userstate>;

  @ViewChild('username') username: ElementRef;

  constructor(public store: Store<ApplicationState>) {
  }

  // Initialising login form
  initialiseLoginForm = () => {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    setTimeout(() => {
      if (this.username) {
        this.username.nativeElement.focus();
      }
    }, 0);
  };

  login = (): void => {
    // Dispatching login event
    this.store.dispatch(new Login(this.loginForm.value));
    this.authenticationState = this.store.pipe(select(userState));
  };

  ngOnInit() {
    this.initialiseLoginForm();
  }

}

import { Action } from '@ngrx/store';
import {
  IBookingStatuses,
  IPaymentStatuses,
  ITourAddon,
  ITourAddonPriceCategory,
  ITourBooking,
  ITourDiscount,
  ITourItinerary,
  ITourPassenger,
  ITourPassengerAddon,
  ITourPriceCategory,
  IPaymentMode,
  IPayment
} from 'src/app/core/models/Tour';

export enum TourActions {
  LOAD_TOURS = '[Tour] Load Tours',
  LOAD_TOURS_SUCCESS = '[Tour] Load Tours Success',
  LOAD_TOURS_FAILED = '[Tour] Load Tours Failed',

  SET_PAGINATION = '[Tour] Set Pagination',
  SET_MESSAGE = '[Tour] Set Message',
  SET_TOUR = '[Tour] Set Tour',
  SET_TOURS = '[Tour] Set Tours',

  LOAD_MORE_TOURS = '[Tour] Load More Tours',
  LOAD_MORE_TOURS_SUCCESS = '[Tour] Load More Tours Success',
  LOAD_MORE_TOURS_FAILED = '[Tour] Load More Tours Failed',

  LOAD_TOUR_BY_ID = '[Tour] Load Tour By ID',
  LOAD_TOUR_BY_ID_SUCCESS = '[Tour] Load Tour By ID Success',
  LOAD_TOUR_BY_ID_FAILED = '[Tour] Load Tour By ID Failed',

  CREATE_TOUR = '[Tour] Create Tour',
  CREATE_TOUR_SUCCESS = '[Tour] Create Tour Success',
  CREATE_TOUR_FAILED = '[Tour] Create Tour Failed',

  UPDATE_TOUR = '[Tour] Update Tour',
  UPDATE_TOUR_SUCCESS = '[Tour] Update Tour Success',
  UPDATE_TOUR_FAILED = '[Tour] Update Tour Failed'
}

export enum TourDiscountActions {
  LOAD_TOUR_DISCOUNTS = '[Tour Discount] Load Tour Discounts',
  LOAD_TOUR_DISCOUNTS_SUCCESS = '[Tour Discount] Load Tour Discounts Success',
  LOAD_TOUR_DISCOUNTS_FAILED = '[Tour Discount] Load Tour Discounts Failed',

  SET_TOUR_DISCOUNT = '[Tour Discount] Set Tour Discount',
  SET_TOUR_DISCOUNTS = '[Tour Discount] Set Tour Discounts',

  CREATE_TOUR_DISCOUNT = '[Tour Discount] Create Tour Discount',
  CREATE_TOUR_DISCOUNT_SUCCESS = '[Tour Discount] Create Tour Discount Success',
  CREATE_TOUR_DISCOUNT_FAILED = '[Tour Discount] Create Tour Discount Failed',

  UPDATE_TOUR_DISCOUNT = '[Tour Discount] Update Tour Discount',
  UPDATE_TOUR_DISCOUNT_SUCCESS = '[Tour Discount] Update Tour Discount Success',
  UPDATE_TOUR_DISCOUNT_FAILED = '[Tour Discount] Update Tour Discount Failed',

  REMOVE_TOUR_DISCOUNT = '[Tour Discount] Remove Tour Discount',
  REMOVE_TOUR_DISCOUNT_SUCCESS = '[Tour Discount] Remove Tour Discount Success',
  REMOVE_TOUR_DISCOUNT_FAILED = '[Tour Discount] Remove Tour Discount Failed',

  LOAD_TOUR_OFFERS = '[Tour Discount] Load Tour Offers',
  LOAD_TOUR_OFFERS_SUCCESS = '[Tour Discount] Load Tour Offers Success',
  LOAD_TOUR_OFFERS_FAILED = '[Tour Discount] Load Tour Offers Failed'
}

export enum TourAddonActions {
  LOAD_TOUR_ADDONS = '[Tour Addon] Load Tour Addons',
  LOAD_TOUR_ADDONS_SUCCESS = '[Tour Addon] Load Tour Addons Success',
  LOAD_TOUR_ADDONS_FAILED = '[Tour Addon] Load Tour Addons Failed',

  SET_TOUR_ADDON = '[Tour Addon] Set Tour Addon',
  SET_TOUR_ADDONS = '[Tour Addon] Set Tour Addons',

  CREATE_TOUR_ADDON = '[Tour Addon] Create Tour Addon',
  CREATE_TOUR_ADDON_SUCCESS = '[Tour Addon] Create Tour Addon Success',
  CREATE_TOUR_ADDON_FAILED = '[Tour Addon] Create Tour Addon Failed',

  UPDATE_TOUR_ADDON = '[Tour Addon] Update Tour Addon',
  UPDATE_TOUR_ADDON_SUCCESS = '[Tour Addon] Update Tour Addon Success',
  UPDATE_TOUR_ADDON_FAILED = '[Tour Addon] Update Tour Addon Failed',

  REMOVE_TOUR_ADDON = '[Tour Addon] Remove Tour Addon',
  REMOVE_TOUR_ADDON_SUCCESS = '[Tour Addon] Remove Tour Addon Success',
  REMOVE_TOUR_ADDON_FAILED = '[Tour Addon] Remove Tour Addon Failed'
}

export enum TourPriceCategoryActions {
  LOAD_TOUR_PRICE_CATEGORIES = '[Tour Price Category] Load Tour Price Categories',
  LOAD_TOUR_PRICE_CATEGORIES_SUCCESS = '[Tour Price Category] Load Tour Price Categories Success',
  LOAD_TOUR_PRICE_CATEGORIES_FAILED = '[Tour Price Category] Load Tour Price Categories Failed',

  SET_TOUR_PRICE_CATEGORY = '[Tour Price Category] Set Tour Price Category',
  SET_TOUR_PRICE_CATEGORIES = '[Tour Price Category] Set Tour Price Categories',

  CREATE_TOUR_PRICE_CATEGORY = '[Tour Price Category] Create Tour Price Category',
  CREATE_TOUR_PRICE_CATEGORY_SUCCESS = '[Tour Price Category] Create Tour Price Category Success',
  CREATE_TOUR_PRICE_CATEGORY_FAILED = '[Tour Price Category] Create Tour Price Category Failed',

  UPDATE_TOUR_PRICE_CATEGORY = '[Tour Price Category] Update Tour Price Category',
  UPDATE_TOUR_PRICE_CATEGORY_SUCCESS = '[Tour Price Category] Update Tour Price Category Success',
  UPDATE_TOUR_PRICE_CATEGORY_FAILED = '[Tour Price Category] Update Tour Price Category Failed',

  REMOVE_TOUR_PRICE_CATEGORY = '[Tour Price Category] Remove Tour Price Category',
  REMOVE_TOUR_PRICE_CATEGORY_SUCCESS = '[Tour Price Category] Remove Tour Price Category Success',
  REMOVE_TOUR_PRICE_CATEGORY_FAILED = '[Tour Price Category] Remove Tour Price Category Failed'
}

export enum TourAddonPriceCategoryActions {
  LOAD_TOUR_ADDON_PRICE_CATEGORIES = '[Addon Price Category] Load Tour Addon Price Categories',
  LOAD_TOUR_ADDON_PRICE_CATEGORIES_SUCCESS = '[Addon Price Category] Load Addon Price Categories Success',
  LOAD_TOUR_ADDON_PRICE_CATEGORIES_FAILED = '[Addon Price Category] Tour Addon Price Categories Failed',

  SET_TOUR_ADDON_PRICE_CATEGORIES = '[Tour Addon Price Category] Set Tour Addon Price Categories',

  LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY = '[Addon Price Category] Load Addons For Tour And Price Category',
  LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY_SUCCESS = '[Addon Price Category] Load Addons For Tour And Price Category Success',
  LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY_FAILED = '[Addon Price Category] Load Addons For Tour And Price Category Failed',

  LOAD_ADDONS_FOR_PASSENGER = '[Addon Price Category] Load Addons For Passenger',
  LOAD_ADDONS_FOR_PASSENGER_SUCCESS = '[Addon Price Category] Load Addons For Passenger Success',
  LOAD_ADDONS_FOR_PASSENGER_FAILED = '[Addon Price Category] Load Addons For Passenger Failed',

  INSERT_ADDON_FOR_PRICE_CATEGORY = '[Addon For Price Category] Insert Addon For Price Category',
  INSERT_ADDON_FOR_PRICE_CATEGORY_SUCCESS = '[Addon For Price Category] Insert Addon For Price Category Success',
  INSERT_ADDON_FOR_PRICE_CATEGORY_FAILED = '[Addon For Price Category] Insert Addon For Price Category Failed',

  DELETE_ADDON_FOR_PRICE_CATEGORY = '[Addon For Price Category] Delete Addon For Price Category',
  DELETE_ADDON_FOR_PRICE_CATEGORY_SUCCESS = '[Addon For Price Category] Delete Addon For Price Category Success',
  DELETE_ADDON_FOR_PRICE_CATEGORY_FAILED = '[Addon For Price Category] Delete Addon For Price Category Failed'
}

export enum TourItineraryActions {
  LOAD_TOUR_ITINERARIES = '[Tour Itinerary] Load Tour Itineraryies',
  LOAD_TOUR_ITINERARIES_SUCCESS = '[Tour Itinerary] Load Tour Itineraryies Success',
  LOAD_TOUR_ITINERARIES_FAILED = '[Tour Itinerary] Load Tour Itineraryies Failed',

  SET_TOUR_ITINERARY = '[Tour Itinerary] Set Tour Itinerary',
  SET_TOUR_ITINERARIES = '[Tour Itinerary] Set Tour Itineraryies',

  CREATE_TOUR_ITINERARY = '[Tour Itinerary] Create Tour Itinerary',
  CREATE_TOUR_ITINERARY_SUCCESS = '[Tour Itinerary] Create Tour Itinerary Success',
  CREATE_TOUR_ITINERARY_FAILED = '[Tour Itinerary] Create Tour Itinerary Failed',

  UPDATE_TOUR_ITINERARY = '[Tour Itinerary] Update Tour Itinerary',
  UPDATE_TOUR_ITINERARY_SUCCESS = '[Tour Itinerary] Update Tour Itinerary Success',
  UPDATE_TOUR_ITINERARY_FAILED = '[Tour Itinerary] Update Tour Itinerary Failed',

  REMOVE_TOUR_ITINERARY = '[Tour Itinerary] Remove Tour Itinerary',
  REMOVE_TOUR_ITINERARY_SUCCESS = '[Tour Itinerary] Remove Tour Itinerary Success',
  REMOVE_TOUR_ITINERARY_FAILED = '[Tour Itinerary] Remove Tour Itinerary Failed'
}

export enum TourBookingActions {
  LOAD_BOOKING_STATUSES = '[Tour Booking] Load Booking Statuses',
  LOAD_BOOKING_STATUSES_SUCCESS = '[Tour Booking] Load Booking Statuses Success',
  LOAD_BOOKING_STATUSES_FAILED = '[Tour Booking] Load Booking Statuses Failed',

  LOAD_PAYMENT_STATUSES = '[Tour Booking] Load Payment Statuses',
  LOAD_PAYMENT_STATUSES_SUCCESS = '[Tour Booking] Load Payment Statuses Success',
  LOAD_PAYMENT_STATUSES_FAILED = '[Tour Booking] Load Payment Statuses Failed',

  LOAD_TOUR_BOOKINGS = '[Tour Booking] Load Tour Bookings',
  LOAD_TOUR_BOOKINGS_SUCCESS = '[Tour Booking] Load Tour Bookings Success',
  LOAD_TOUR_BOOKINGS_FAILED = '[Tour Booking] Load Tour Bookings Failed',

  SET_TOUR_BOOKING = '[Tour Booking] Set Tour Booking',
  SET_TOUR_BOOKINGS = '[Tour Booking] Set Tour Bookings',
  SET_BOOKING_FOR_PAYMENT = '[Tour Booking] Set Booking For Payment',

  LOAD_TOUR_BOOKING = '[Tour Booking] Load Tour Booking',
  LOAD_TOUR_BOOKING_SUCCESS = '[Tour Booking] Load Tour Booking Success',
  LOAD_TOUR_BOOKING_FAILED = '[Tour Booking] Load Tour Booking Failed',

  CREATE_TOUR_BOOKING = '[Tour Booking] Create Tour Booking',
  CREATE_TOUR_BOOKING_SUCCESS = '[Tour Booking] Create Tour Booking Success',
  CREATE_TOUR_BOOKING_FAILED = '[Tour Booking] Create Tour Booking Failed',

  UPDATE_TOUR_BOOKING = '[Tour Booking] Update Tour Booking',
  UPDATE_TOUR_BOOKING_SUCCESS = '[Tour Booking] Update Tour Booking Success',
  UPDATE_TOUR_BOOKING_FAILED = '[Tour Booking] Update Tour Booking Failed',

  REMOVE_TOUR_BOOKING = '[Tour Booking] Remove Tour Booking',
  REMOVE_TOUR_BOOKING_SUCCESS = '[Tour Booking] Remove Tour Booking Success',
  REMOVE_TOUR_BOOKING_FAILED = '[Tour Booking] Remove Tour Booking Failed',

  FETCH_TOUR_BOOKING_TOTAL_FEE = '[Tour Booking] Fetch Tour Booking Total Fee',
  FETCH_TOUR_BOOKING_TOTAL_FEE_SUCCESS = '[Tour Booking] Fetch Tour Booking Total Fee Success',
  FETCH_TOUR_BOOKING_TOTAL_FEE_FAILED = '[Tour Booking] Fetch Tour Booking Total Fee Failed',

  LOAD_TOUR_BOOKING_PAYMENT_MODES = '[Tour Booking] Load Tour Booking Payment Modes',
  LOAD_TOUR_BOOKING_PAYMENT_MODES_SUCCESS = '[Tour Booking] Load Tour Booking Payment Modes Success',
  LOAD_TOUR_BOOKING_PAYMENT_MODES_FAILED = '[Tour Booking] Load Tour Booking Payment Modes Failed',

  LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING = '[Tour Booking] Load Tour Booking Payment History By Booking',
  LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_SUCCESS = '[Tour Booking] Load Tour Booking Payment History By Booking Success',
  LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_FAILED = '[Tour Booking] Load Tour Booking Payment History By Booking Failed',

  CREATE_PAYMENT = '[Tour Booking] Create Payment',
  CREATE_PAYMENT_SUCCESS = '[Tour Booking] Create Payment Success',
  CREATE_PAYMENT_FAILED = '[Tour Booking] Create Payment Failed'
}

export enum TourPassengerActions {
  LOAD_TOUR_PASSENGERS = '[Tour Passenger] Load Tour Passengers',
  LOAD_TOUR_PASSENGERS_SUCCESS = '[Tour Passenger] Load Tour Passengers Success',
  LOAD_TOUR_PASSENGERS_FAILED = '[Tour Passenger] Load Tour Passengers Failed',

  SET_TOUR_PASSENGER = '[Tour Passenger] Set Tour Passenger',
  SET_TOUR_PASSENGERS = '[Tour Passenger] Set Tour Passengers',

  CREATE_TOUR_PASSENGER = '[Tour Passenger] Create Tour Passenger',
  CREATE_TOUR_PASSENGER_SUCCESS = '[Tour Passenger] Create Tour Passenger Success',
  CREATE_TOUR_PASSENGER_FAILED = '[Tour Passenger] Create Tour Passenger Failed',

  UPDATE_TOUR_PASSENGER = '[Tour Passenger] Update Tour Passenger',
  UPDATE_TOUR_PASSENGER_SUCCESS = '[Tour Passenger] Update Tour Passenger Success',
  UPDATE_TOUR_PASSENGER_FAILED = '[Tour Passenger] Update Tour Passenger Failed',

  REMOVE_TOUR_PASSENGER = '[Tour Passenger] Remove Tour Passenger',
  REMOVE_TOUR_PASSENGER_SUCCESS = '[Tour Passenger] Remove Tour Passenger Success',
  REMOVE_TOUR_PASSENGER_FAILED = '[Tour Passenger] Remove Tour Passenger Failed'
}

export class LoadTours implements Action {
  readonly type: string = TourActions.LOAD_TOURS;

  constructor(public payload: any, public pagination?: { page: number, limit: number }, public search?: { [id: string]: any }) {
  }
}

export class LoadToursSuccess implements Action {
  readonly type: string = TourActions.LOAD_TOURS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadToursFailed implements Action {
  readonly type: string = TourActions.LOAD_TOURS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetPagination implements Action {
  readonly type: string = TourActions.SET_PAGINATION;

  constructor(public payload: any) {
  }
}

export class SetMessage implements Action {
  readonly type: string = TourActions.SET_MESSAGE;

  constructor(public payload: any) {
  }
}

export class SetTour implements Action {
  readonly type: string = TourActions.SET_TOUR;

  constructor(public payload: any) {
  }
}

export class SetTours implements Action {
  readonly type: string = TourActions.SET_TOURS;

  constructor(public payload: any) {
  }
}

export class LoadMoreTours implements Action {
  readonly type: string = TourActions.LOAD_MORE_TOURS;

  constructor(public payload: any, public pagination?: { page: number, limit: number }, public search?: { [id: string]: any }) {
  }
}

export class LoadMoreToursSuccess implements Action {
  readonly type: string = TourActions.LOAD_MORE_TOURS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadMoreToursFailed implements Action {
  readonly type: string = TourActions.LOAD_MORE_TOURS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourByID implements Action {
  readonly type: string = TourActions.LOAD_TOUR_BY_ID;

  constructor(public payload: number) {
  }
}

export class LoadTourByIDSuccess implements Action {
  readonly type: string = TourActions.LOAD_TOUR_BY_ID_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadTourByIDFailed implements Action {
  readonly type: string = TourActions.LOAD_TOUR_BY_ID_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateTour implements Action {
  readonly type: string = TourActions.CREATE_TOUR;

  constructor(public payload: any) {
  }
}

export class CreateTourSuccess implements Action {
  readonly type: string = TourActions.CREATE_TOUR_SUCCESS;

  constructor(public payload: any) {
  }
}

export class CreateTourFailed implements Action {
  readonly type: string = TourActions.CREATE_TOUR_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTour implements Action {
  readonly type: string = TourActions.UPDATE_TOUR;

  constructor(public payload: any) {
  }
}

export class UpdateTourSuccess implements Action {
  readonly type: string = TourActions.UPDATE_TOUR_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateTourFailed implements Action {
  readonly type: string = TourActions.UPDATE_TOUR_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourDiscounts implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_DISCOUNTS;

  constructor(public payload: number) {
  }
}

export class LoadTourDiscountsSuccess implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_DISCOUNTS_SUCCESS;

  constructor(public payload: ITourDiscount[]) {
  }
}

export class LoadTourDiscountsFailed implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_DISCOUNTS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourDiscount implements Action {
  readonly type: string = TourDiscountActions.SET_TOUR_DISCOUNT;

  constructor(public payload: ITourDiscount) {
  }
}

export class SetTourDiscounts implements Action {
  readonly type: string = TourDiscountActions.SET_TOUR_DISCOUNTS;

  constructor(public payload: ITourDiscount[]) {
  }
}

export class CreateTourDiscount implements Action {
  readonly type: string = TourDiscountActions.CREATE_TOUR_DISCOUNT;

  constructor(public payload: ITourDiscount) {
  }
}

export class CreateTourDiscountSuccess implements Action {
  readonly type: string = TourDiscountActions.CREATE_TOUR_DISCOUNT_SUCCESS;

  constructor(public payload: ITourDiscount) {
  }
}

export class CreateTourDiscountFailed implements Action {
  readonly type: string = TourDiscountActions.CREATE_TOUR_DISCOUNT_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourDiscount implements Action {
  readonly type: string = TourDiscountActions.UPDATE_TOUR_DISCOUNT;

  constructor(public payload: ITourDiscount) {
  }
}

export class UpdateTourDiscountSuccess implements Action {
  readonly type: string = TourDiscountActions.UPDATE_TOUR_DISCOUNT_SUCCESS;

  constructor(public payload: ITourDiscount) {
  }
}

export class UpdateTourDiscountFailed implements Action {
  readonly type: string = TourDiscountActions.UPDATE_TOUR_DISCOUNT_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourDiscount implements Action {
  readonly type: string = TourDiscountActions.REMOVE_TOUR_DISCOUNT;

  constructor(public payload: number) {
  }
}

export class RemoveTourDiscountSuccess implements Action {
  readonly type: string = TourDiscountActions.REMOVE_TOUR_DISCOUNT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourDiscountFailed implements Action {
  readonly type: string = TourDiscountActions.REMOVE_TOUR_DISCOUNT_FAILED;

  constructor(public payload: any, public discountId?: number) {
  }
}

export class LoadTourOffers implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_OFFERS;

  constructor(public payload: { tourId: number, date: string }) {
  }
}

export class LoadTourOffersSuccess implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_OFFERS_SUCCESS;

  constructor(public payload: ITourDiscount[]) {
  }
}

export class LoadTourOffersFailed implements Action {
  readonly type: string = TourDiscountActions.LOAD_TOUR_OFFERS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourAddons implements Action {
  readonly type: string = TourAddonActions.LOAD_TOUR_ADDONS;

  constructor(public payload: number) {
  }
}

export class LoadTourAddonsSuccess implements Action {
  readonly type: string = TourAddonActions.LOAD_TOUR_ADDONS_SUCCESS;

  constructor(public payload: ITourAddon[]) {
  }
}

export class LoadTourAddonsFailed implements Action {
  readonly type: string = TourAddonActions.LOAD_TOUR_ADDONS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourAddon implements Action {
  readonly type: string = TourAddonActions.SET_TOUR_ADDON;

  constructor(public payload: ITourAddon) {
  }
}

export class SetTourAddons implements Action {
  readonly type: string = TourAddonActions.SET_TOUR_ADDONS;

  constructor(public payload: ITourAddon[]) {
  }
}

export class CreateTourAddon implements Action {
  readonly type: string = TourAddonActions.CREATE_TOUR_ADDON;

  constructor(public payload: ITourAddon) {
  }
}

export class CreateTourAddonSuccess implements Action {
  readonly type: string = TourAddonActions.CREATE_TOUR_ADDON_SUCCESS;

  constructor(public payload: ITourAddon) {
  }
}

export class CreateTourAddonFailed implements Action {
  readonly type: string = TourAddonActions.CREATE_TOUR_ADDON_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourAddon implements Action {
  readonly type: string = TourAddonActions.UPDATE_TOUR_ADDON;

  constructor(public payload: ITourAddon) {
  }
}

export class UpdateTourAddonSuccess implements Action {
  readonly type: string = TourAddonActions.UPDATE_TOUR_ADDON_SUCCESS;

  constructor(public payload: ITourAddon) {
  }
}

export class UpdateTourAddonFailed implements Action {
  readonly type: string = TourAddonActions.UPDATE_TOUR_ADDON_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourAddon implements Action {
  readonly type: string = TourAddonActions.REMOVE_TOUR_ADDON;

  constructor(public payload: number) {
  }
}

export class RemoveTourAddonSuccess implements Action {
  readonly type: string = TourAddonActions.REMOVE_TOUR_ADDON_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourAddonFailed implements Action {
  readonly type: string = TourAddonActions.REMOVE_TOUR_ADDON_FAILED;

  constructor(public payload: any, public addonId?: number) {
  }
}

export class LoadTourPriceCategories implements Action {
  readonly type: string = TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES;

  constructor(public payload: number) {
  }
}

export class LoadTourPriceCategoriesSuccess implements Action {
  readonly type: string = TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES_SUCCESS;

  constructor(public payload: ITourPriceCategory[]) {
  }
}

export class LoadTourPriceCategoriesFailed implements Action {
  readonly type: string = TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourPriceCategory implements Action {
  readonly type: string = TourPriceCategoryActions.SET_TOUR_PRICE_CATEGORY;

  constructor(public payload: ITourPriceCategory) {
  }
}

export class SetTourPriceCategories implements Action {
  readonly type: string = TourPriceCategoryActions.SET_TOUR_PRICE_CATEGORIES;

  constructor(public payload: ITourPriceCategory[]) {
  }
}

export class CreateTourPriceCategory implements Action {
  readonly type: string = TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY;

  constructor(public payload: ITourPriceCategory) {
  }
}

export class CreateTourPriceCategorySuccess implements Action {
  readonly type: string = TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: ITourPriceCategory) {
  }
}

export class CreateTourPriceCategoryFailed implements Action {
  readonly type: string = TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourPriceCategory implements Action {
  readonly type: string = TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY;

  constructor(public payload: ITourPriceCategory) {
  }
}

export class UpdateTourPriceCategorySuccess implements Action {
  readonly type: string = TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: ITourPriceCategory) {
  }
}

export class UpdateTourPriceCategoryFailed implements Action {
  readonly type: string = TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourPriceCategory implements Action {
  readonly type: string = TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY;

  constructor(public payload: number) {
  }
}

export class RemoveTourPriceCategorySuccess implements Action {
  readonly type: string = TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourPriceCategoryFailed implements Action {
  readonly type: string = TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY_FAILED;

  constructor(public payload: any, public priceCategoryId?: number) {
  }
}

export class LoadTourAddonPriceCategories implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES;

  constructor(public payload: number) {
  }
}

export class LoadTourAddonPriceCategoriesSuccess implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES_SUCCESS;

  constructor(public payload: { double: ITourAddonPriceCategory[], single: ITourAddonPriceCategory[] }) {
  }
}

export class LoadTourAddonPriceCategoriesFailed implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourAddonPriceCategories implements Action {
  readonly type: string = TourAddonPriceCategoryActions.SET_TOUR_ADDON_PRICE_CATEGORIES;

  constructor(public payload: any) {
  }
}

export class LoadAddonsForTourAndPriceCategory implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY;

  constructor(public payload: { tourId: number, priceCategoryId: number }) {
  }
}

export class LoadAddonsForTourAndPriceCategorySuccess implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadAddonsForTourAndPriceCategoryFailed implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAddonsForPassenger implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_PASSENGER;

  constructor(public payload: number) {
  }
}

export class LoadAddonsForPassengerSuccess implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_PASSENGER_SUCCESS;

  constructor(public payload: ITourPassengerAddon[]) {
  }
}

export class LoadAddonsForPassengerFailed implements Action {
  readonly type: string = TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_PASSENGER_FAILED;

  constructor(public payload: any) {
  }
}

export class InsertAddonForPriceCategory implements Action {
  readonly type: string = TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY;

  constructor(public payload: { price_category_id: number, add_on_id: number, occupancy_type: string }) {
  }
}

export class InsertAddonForPriceCategorySuccess implements Action {
  readonly type: string = TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: { price_category_id: number, add_on_id: number, occupancy_type: string }) {
  }
}

export class InsertAddonForPriceCategoryFailed implements Action {
  readonly type: string = TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY_FAILED;

  constructor(public payload: any) {
  }
}

export class DeletetAddonForPriceCategory implements Action {
  readonly type: string = TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY;

  constructor(public payload: { price_category_id: number, add_on_id: number, occupancy_type: string }) {
  }
}

export class DeletetAddonForPriceCategorySuccess implements Action {
  readonly type: string = TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY_SUCCESS;

  constructor(public payload: { price_category_id: number, add_on_id: number, occupancy_type: string }) {
  }
}

export class DeletetAddonForPriceCategoryFailed implements Action {
  readonly type: string = TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourItineraries implements Action {
  readonly type: string = TourItineraryActions.LOAD_TOUR_ITINERARIES;

  constructor(public payload: number) {
  }
}

export class LoadTourItinerariesSuccess implements Action {
  readonly type: string = TourItineraryActions.LOAD_TOUR_ITINERARIES_SUCCESS;

  constructor(public payload: ITourItinerary[]) {
  }
}

export class LoadTourItinerariesFailed implements Action {
  readonly type: string = TourItineraryActions.LOAD_TOUR_ITINERARIES_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourItinerary implements Action {
  readonly type: string = TourItineraryActions.SET_TOUR_ITINERARY;

  constructor(public payload: ITourItinerary) {
  }
}

export class SetTourItineraries implements Action {
  readonly type: string = TourItineraryActions.SET_TOUR_ITINERARIES;

  constructor(public payload: ITourItinerary[]) {
  }
}

export class CreateTourItinerary implements Action {
  readonly type: string = TourItineraryActions.CREATE_TOUR_ITINERARY;

  constructor(public payload: ITourItinerary) {
  }
}

export class CreateTourItinerarySuccess implements Action {
  readonly type: string = TourItineraryActions.CREATE_TOUR_ITINERARY_SUCCESS;

  constructor(public payload: ITourItinerary) {
  }
}

export class CreateTourItineraryFailed implements Action {
  readonly type: string = TourItineraryActions.CREATE_TOUR_ITINERARY_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourItinerary implements Action {
  readonly type: string = TourItineraryActions.UPDATE_TOUR_ITINERARY;

  constructor(public payload: ITourItinerary) {
  }
}

export class UpdateTourItinerarySuccess implements Action {
  readonly type: string = TourItineraryActions.UPDATE_TOUR_ITINERARY_SUCCESS;

  constructor(public payload: ITourItinerary) {
  }
}

export class UpdateTourItineraryFailed implements Action {
  readonly type: string = TourItineraryActions.UPDATE_TOUR_ITINERARY_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourItinerary implements Action {
  readonly type: string = TourItineraryActions.REMOVE_TOUR_ITINERARY;

  constructor(public payload: number) {
  }
}

export class RemoveTourItinerarySuccess implements Action {
  readonly type: string = TourItineraryActions.REMOVE_TOUR_ITINERARY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourItineraryFailed implements Action {
  readonly type: string = TourItineraryActions.REMOVE_TOUR_ITINERARY_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadBookingStatuses implements Action {
  readonly type: string = TourBookingActions.LOAD_BOOKING_STATUSES;

  constructor(public payload: any) {
  }
}

export class LoadBookingStatusesSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_BOOKING_STATUSES_SUCCESS;

  constructor(public payload: IBookingStatuses[]) {
  }
}

export class LoadBookingStatusesFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_BOOKING_STATUSES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadPaymentStatuses implements Action {
  readonly type: string = TourBookingActions.LOAD_PAYMENT_STATUSES;

  constructor(public payload: any) {
  }
}

export class LoadPaymentStatusesSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_PAYMENT_STATUSES_SUCCESS;

  constructor(public payload: IPaymentStatuses[]) {
  }
}

export class LoadPaymentStatusesFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_PAYMENT_STATUSES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourBookings implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKINGS;

  constructor(public payload: number) {
  }
}

export class LoadTourBookingsSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKINGS_SUCCESS;

  constructor(public payload: ITourBooking[]) {
  }
}

export class LoadTourBookingsFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKINGS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourBooking implements Action {
  readonly type: string = TourBookingActions.SET_TOUR_BOOKING;

  constructor(public payload: ITourBooking) {
  }
}

export class SetTourBookings implements Action {
  readonly type: string = TourBookingActions.SET_TOUR_BOOKINGS;

  constructor(public payload: ITourBooking[]) {
  }
}

export class SetBookingForPayment implements Action {
  readonly type: string = TourBookingActions.SET_BOOKING_FOR_PAYMENT;

  constructor(public payload: ITourBooking) {
  }
}

export class LoadTourBooking implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING;

  constructor(public payload: number) {
  }
}

export class LoadTourBookingSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_SUCCESS;

  constructor(public payload: ITourBooking) {
  }
}

export class LoadTourBookingFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateTourBooking implements Action {
  readonly type: string = TourBookingActions.CREATE_TOUR_BOOKING;

  constructor(public payload: ITourBooking) {
  }
}

export class CreateTourBookingSuccess implements Action {
  readonly type: string = TourBookingActions.CREATE_TOUR_BOOKING_SUCCESS;

  constructor(public payload: ITourBooking) {
  }
}

export class CreateTourBookingFailed implements Action {
  readonly type: string = TourBookingActions.CREATE_TOUR_BOOKING_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourBooking implements Action {
  readonly type: string = TourBookingActions.UPDATE_TOUR_BOOKING;

  constructor(public payload: ITourBooking) {
  }
}

export class UpdateTourBookingSuccess implements Action {
  readonly type: string = TourBookingActions.UPDATE_TOUR_BOOKING_SUCCESS;

  constructor(public payload: ITourBooking) {
  }
}

export class UpdateTourBookingFailed implements Action {
  readonly type: string = TourBookingActions.UPDATE_TOUR_BOOKING_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourBooking implements Action {
  readonly type: string = TourBookingActions.REMOVE_TOUR_BOOKING;

  constructor(public payload: number) {
  }
}

export class RemoveTourBookingSuccess implements Action {
  readonly type: string = TourBookingActions.REMOVE_TOUR_BOOKING_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourBookingFailed implements Action {
  readonly type: string = TourBookingActions.REMOVE_TOUR_BOOKING_FAILED;

  constructor(public payload: any) {
  }
}

export class FetchTourBookingTotalFee implements Action {
  readonly type: string = TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE;

  constructor(public payload: number) {
  }
}

export class FetchTourBookingTotalFeeSuccess implements Action {
  readonly type: string = TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE_SUCCESS;

  constructor(public payload: ITourBooking[]) {
  }
}

export class FetchTourBookingTotalFeeFailed implements Action {
  readonly type: string = TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourBookingPaymentModes implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES;

  constructor(public payload: any) {
  }
}

export class LoadTourBookingPaymentModesSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES_SUCCESS;

  constructor(public payload: IPaymentMode[]) {
  }
}

export class LoadTourBookingPaymentModesFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourBookingPaymentHistoryByBooking implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING;

  constructor(public payload: number) {
  }
}

export class LoadTourBookingPaymentHistoryByBookingSuccess implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_SUCCESS;

  constructor(public payload: IPayment[]) {
  }
}

export class LoadTourBookingPaymentHistoryByBookingFailed implements Action {
  readonly type: string = TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_FAILED;

  constructor(public payload: any) {
  }
}

export class CreatePayment implements Action {
  readonly type: string = TourBookingActions.CREATE_PAYMENT;

  constructor(public payload: IPayment) {
  }
}

export class CreatePaymentSuccess implements Action {
  readonly type: string = TourBookingActions.CREATE_PAYMENT_SUCCESS;

  constructor(public payload: IPayment) {
  }
}

export class CreatePaymentFailed implements Action {
  readonly type: string = TourBookingActions.CREATE_PAYMENT_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTourPassengers implements Action {
  readonly type: string = TourPassengerActions.LOAD_TOUR_PASSENGERS;

  constructor(public payload: number) {
  }
}

export class LoadTourPassengersSuccess implements Action {
  readonly type: string = TourPassengerActions.LOAD_TOUR_PASSENGERS_SUCCESS;

  constructor(public payload: ITourPassenger[]) {
  }
}

export class LoadTourPassengersFailed implements Action {
  readonly type: string = TourPassengerActions.LOAD_TOUR_PASSENGERS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetTourPassenger implements Action {
  readonly type: string = TourPassengerActions.SET_TOUR_PASSENGER;

  constructor(public payload: ITourPassenger) {
  }
}

export class SetTourPassengers implements Action {
  readonly type: string = TourPassengerActions.SET_TOUR_PASSENGERS;

  constructor(public payload: ITourPassenger[]) {
  }
}

export class CreateTourPassenger implements Action {
  readonly type: string = TourPassengerActions.CREATE_TOUR_PASSENGER;

  constructor(public payload: ITourPassenger) {
  }
}

export class CreateTourPassengerSuccess implements Action {
  readonly type: string = TourPassengerActions.CREATE_TOUR_PASSENGER_SUCCESS;

  constructor(public payload: ITourPassenger) {
  }
}

export class CreateTourPassengerFailed implements Action {
  readonly type: string = TourPassengerActions.CREATE_TOUR_PASSENGER_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateTourPassenger implements Action {
  readonly type: string = TourPassengerActions.UPDATE_TOUR_PASSENGER;

  constructor(public payload: ITourPassenger) {
  }
}

export class UpdateTourPassengerSuccess implements Action {
  readonly type: string = TourPassengerActions.UPDATE_TOUR_PASSENGER_SUCCESS;

  constructor(public payload: ITourPassenger) {
  }
}

export class UpdateTourPassengerFailed implements Action {
  readonly type: string = TourPassengerActions.UPDATE_TOUR_PASSENGER_FAILED;

  constructor(public payload: any) {
  }
}

export class RemoveTourPassenger implements Action {
  readonly type: string = TourPassengerActions.REMOVE_TOUR_PASSENGER;

  constructor(public payload: { passengerId: number, bookingId: number }) {
  }
}

export class RemoveTourPassengerSuccess implements Action {
  readonly type: string = TourPassengerActions.REMOVE_TOUR_PASSENGER_SUCCESS;

  constructor(public payload: any) {
  }
}

export class RemoveTourPassengerFailed implements Action {
  readonly type: string = TourPassengerActions.REMOVE_TOUR_PASSENGER_FAILED;

  constructor(public payload: any) {
  }
}

export type TourAction =
  // Tour
  LoadTours | LoadToursSuccess | LoadToursFailed |
  SetPagination | SetMessage | SetTour | SetTours |
  LoadMoreTours | LoadMoreToursSuccess | LoadMoreToursFailed |
  LoadTourByID | LoadTourByIDSuccess | LoadTourByIDFailed |
  CreateTour | CreateTourSuccess | CreateTourFailed |
  UpdateTour | UpdateTourSuccess | UpdateTourFailed |
  // Tour Discount
  LoadTourDiscounts | LoadTourDiscountsSuccess | LoadTourDiscountsFailed |
  SetTourDiscount | SetTourDiscounts |
  CreateTourDiscount | CreateTourDiscountSuccess | CreateTourDiscountFailed |
  UpdateTourDiscount | UpdateTourDiscountSuccess | UpdateTourDiscountFailed |
  RemoveTourDiscount | RemoveTourDiscountSuccess | RemoveTourDiscountFailed |
  LoadTourOffers | LoadTourOffersSuccess | LoadTourOffersFailed |
  // Tour Addon
  LoadTourAddons | LoadTourAddonsSuccess | LoadTourAddonsFailed |
  SetTourAddon | SetTourAddons |
  CreateTourAddon | CreateTourAddonSuccess | CreateTourAddonFailed |
  UpdateTourAddon | UpdateTourAddonSuccess | UpdateTourAddonFailed |
  RemoveTourAddon | RemoveTourAddonSuccess | RemoveTourAddonFailed |
  // Tour Prce Category
  LoadTourPriceCategories | LoadTourPriceCategoriesSuccess | LoadTourPriceCategoriesFailed |
  SetTourPriceCategory | SetTourPriceCategories |
  CreateTourPriceCategory | CreateTourPriceCategorySuccess | CreateTourPriceCategoryFailed |
  UpdateTourPriceCategory | UpdateTourPriceCategorySuccess | UpdateTourPriceCategoryFailed |
  RemoveTourPriceCategory | RemoveTourPriceCategorySuccess | RemoveTourPriceCategoryFailed |
  // Tour Addon Prce Category
  LoadTourAddonPriceCategories | LoadTourAddonPriceCategoriesSuccess | LoadTourAddonPriceCategoriesFailed |
  SetTourAddonPriceCategories |
  LoadAddonsForTourAndPriceCategory | LoadAddonsForTourAndPriceCategorySuccess | LoadAddonsForTourAndPriceCategoryFailed |
  LoadAddonsForPassenger | LoadAddonsForPassengerSuccess | LoadAddonsForPassengerFailed |
  InsertAddonForPriceCategory | InsertAddonForPriceCategorySuccess | InsertAddonForPriceCategoryFailed |
  DeletetAddonForPriceCategory | DeletetAddonForPriceCategorySuccess | DeletetAddonForPriceCategoryFailed |
  // Tour Itinerary
  LoadTourItineraries | LoadTourItinerariesSuccess | LoadTourItinerariesFailed |
  SetTourItinerary | SetTourItineraries |
  CreateTourItinerary | CreateTourItinerarySuccess | CreateTourItineraryFailed |
  UpdateTourItinerary | UpdateTourItinerarySuccess | UpdateTourItineraryFailed |
  RemoveTourItinerary | RemoveTourItinerarySuccess | RemoveTourItineraryFailed |
  // Tour Booking
  LoadBookingStatuses | LoadBookingStatusesSuccess | LoadBookingStatusesFailed |
  LoadPaymentStatuses | LoadPaymentStatusesSuccess | LoadPaymentStatusesFailed |
  LoadTourBookings | LoadTourBookingsSuccess | LoadTourBookingsFailed |
  SetTourBooking | SetTourBookings | SetBookingForPayment |
  LoadTourBooking | LoadTourBookingSuccess | LoadTourBookingFailed |
  CreateTourBooking | CreateTourBookingSuccess | CreateTourBookingFailed |
  UpdateTourBooking | UpdateTourBookingSuccess | UpdateTourBookingFailed |
  RemoveTourBooking | RemoveTourBookingSuccess | RemoveTourBookingFailed |
  FetchTourBookingTotalFee | FetchTourBookingTotalFeeSuccess | FetchTourBookingTotalFeeFailed |
  LoadTourBookingPaymentModes | LoadTourBookingPaymentModesSuccess | LoadTourBookingPaymentModesFailed |
  LoadTourBookingPaymentHistoryByBooking | LoadTourBookingPaymentHistoryByBookingSuccess | LoadTourBookingPaymentHistoryByBookingFailed |
  CreatePayment | CreatePaymentSuccess | CreatePaymentFailed |
  // Tour Passenger
  LoadTourPassengers | LoadTourPassengersSuccess | LoadTourPassengersFailed |
  SetTourPassenger | SetTourPassengers |
  CreateTourPassenger | CreateTourPassengerSuccess | CreateTourPassengerFailed |
  UpdateTourPassenger | UpdateTourPassengerSuccess | UpdateTourPassengerFailed |
  RemoveTourPassenger | RemoveTourPassengerSuccess | RemoveTourPassengerFailed

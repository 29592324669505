import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-side-panel-header',
  templateUrl: './side-panel-header.component.html',
  styleUrls: ['./side-panel-header.component.css']
})
export class SidePanelHeaderComponent implements OnInit {
  @Input() headerText: string;
  @Input() entityName: string;

  @Output() cancel = new EventEmitter();

  constructor() {
  }

  cancelEvent = () => {
    this.cancel.emit();
  };

  ngOnInit() {
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {LoginRoutingModule} from './login-routing.module';
import {LoginContainerComponent} from './login-container/login-container.component';
import {LoginFormComponent} from './login-form/login-form.component';
import {PersonalizeCompanyFormComponent} from './personalize-company-form/personalize-company-form.component';

@NgModule({
  declarations: [LoginContainerComponent, LoginFormComponent, PersonalizeCompanyFormComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ]
})
export class LoginModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-container-header',
  templateUrl: './container-header.component.html',
  styleUrls: ['./container-header.component.css']
})
export class ContainerHeaderComponent implements OnInit {
  searchForm: FormGroup;

  @Input() headerName: string;
  @Input() headerIcon: string;
  @Input() currentRecordCount: number;
  @Input() recordCount: number;
  @Input() createBtnText: string;
  @Input() updateBtnText: string;
  @Input() showRefreshBtn: boolean;
  @Input() showFilterBtn: boolean;
  @Input() showBackBtn: boolean;
  @Input() disableCreate: boolean;
  @Input() appliedFilterCount: number;

  @Output() searchText = new EventEmitter<string>();
  @Output() refresh = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  @Output() resetFilter = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor() {
  }

  initialiseSearchForm = () => {
    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
    this.searchForm.valueChanges.subscribe((data: any) => {
      this.searchText.emit(data.search);
    });
  };

  ngOnInit() {
    this.initialiseSearchForm();
  }

  refreshEvent = () => {
    this.refresh.emit();
  };

  createEvent = () => {
    this.create.emit();
  };

  updateEvent = () => {
    this.update.emit();
  };

  backEvent = () => {
    this.back.emit();
  };

  applyFilterEvent = () => {
    this.applyFilter.emit();
  };

  resetFilterEvent = () => {
    this.resetFilter.emit();
  };

}

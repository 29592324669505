import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { IGeographicCode } from '../models/GeographicCode';

@Injectable({ providedIn: 'root' })
export class GeographicCodeService {
  constructor(private http: HttpClient) {
  }

  /* Fetch geographic codes */
  public fetchGeographicCodes = (): Observable<IGeographicCode[]> => {
    return this.http.get<IGeographicCode[]>(`${environment.api_service}/api/geographiccode`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Fetch geographic codes by ID */
  public fetchGeographicCodeByID = (geographicCodeId: number): Observable<IGeographicCode> => {
    return this.http.get<IGeographicCode>(`${environment.api_service}/api/geographiccode/${geographicCodeId}`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data[0];
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  /* Create new geographic codes */
  createGeographicCode = (geographicCode: IGeographicCode) => {
    return this.http.post<IGeographicCode>(`${environment.api_service}/api/geographiccode`, geographicCode).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Update geographic codes by id */
  updateGeographicCode = (geographicCode: IGeographicCode) => {
    return this.http.put<IGeographicCode>(`${environment.api_service}/api/geographiccode/${geographicCode.id}`, geographicCode).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  /* Remove geographic codes by id */
  removeGeographicCode = (geographicCodeId: number) => {
    return this.http.delete<any>(`${environment.api_service}/api/geographiccode/${geographicCodeId}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

}

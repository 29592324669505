import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-info-section',
  templateUrl: './message-info-section.component.html',
  styleUrls: ['./message-info-section.component.css']
})
export class MessageInfoSectionComponent implements OnInit {
  @Input() status: number;
  @Input() data: string;

  constructor() {
  }

  ngOnInit() {
  }

}

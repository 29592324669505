import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-container-icon',
  templateUrl: './container-icon.component.html',
  styleUrls: ['./container-icon.component.css']
})
export class ContainerIconComponent implements OnInit {
  @Input() listIcon: string;

  constructor() {
  }

  ngOnInit() {
  }

}

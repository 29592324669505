import * as companyActions from './company.actions';
import { ICompany } from 'src/app/core/models/Company';

export function companyReducer(state: any, action: companyActions.CompanyAction): any {
  switch (action.type) {
    case companyActions.CompanyActions.LOAD_COMPANIES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case companyActions.CompanyActions.LOAD_COMPANIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        companies: action.payload,
        message: null
      };
    }
    case companyActions.CompanyActions.LOAD_COMPANIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        companies: null,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    case companyActions.CompanyActions.SET_COMPANY: {
      return {
        ...state,
        isProcessing: false,
        company: action.payload,
        message: null
      };
    }
    case companyActions.CompanyActions.SET_COMPANIES: {
      return {
        ...state,
        isProcessing: false,
        companies: action.payload,
        message: null
      };
    }
    case companyActions.CompanyActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case companyActions.CompanyActions.CREATE_COMPANY: {
      return {
        ...state,
        isProcessing: true,
        company: null,
        message: null
      };
    }
    case companyActions.CompanyActions.CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        companies: [...state.companies, ...action.payload],
        message: {
          status: 201,
          info: 'Company created successfully'
        }
      };
    }
    case companyActions.CompanyActions.CREATE_COMPANY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    case companyActions.CompanyActions.UPDATE_COMPANY: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case companyActions.CompanyActions.UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        company: null,
        companies: [
          ...state.companies.filter((company: ICompany) => company.id !== action.payload.id),
          ...action.payload
        ],
        message: {
          status: 200,
          info: 'Company updated successfully'
        }
      };
    }
    case companyActions.CompanyActions.UPDATE_COMPANY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    case companyActions.CompanyActions.REMOVE_COMPANY: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case companyActions.CompanyActions.REMOVE_COMPANY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        company: null,
        companies: state.companies.filter((company: ICompany) => company.id !== action.payload),
        message: {
          status: 200,
          info: 'Company removed successfully'
        }
      };
    }
    case companyActions.CompanyActions.REMOVE_COMPANY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    default: {
      return state;
    }
  }
}

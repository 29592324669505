import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-update-button',
  templateUrl: './update-button.component.html',
  styleUrls: ['./update-button.component.css']
})
export class UpdateButtonComponent implements OnInit {
  @Input() btnText: string;
  @Input() disabled: boolean;
  @Input() entityName: string;

  @Output() update = new EventEmitter();

  constructor() {
  }

  updateEvent = () => {
    this.update.emit();
  };

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(private http: HttpClient) {
  }

  public hasDataChanges = (newData: any, oldData: any): Observable<boolean> => {
    let canUpdate: boolean;
    for (let key in newData) {
      if (
        (newData[key] === undefined && oldData[key] === undefined) ||
        typeof newData[key] === 'object' ||
        newData[key] === oldData[key]
      ) {
        // console.info(key, typeof newData[key] === 'object', newData[key], oldData[key], (newData[key] === undefined && oldData[key] === undefined) || newData[key] === oldData[key]);
        canUpdate = false;
      } else {
        // console.info(key, typeof newData[key] === 'object', newData[key], oldData[key], (newData[key] === undefined && oldData[key] === undefined) || newData[key] === oldData[key]);
        return of((canUpdate = true));
      }
    }
    return of(canUpdate);
  };

  /* Fetch all statuses */
  fetchStatuses = (): any => {
    return this.http.get<any>(`${environment.api_service}/api/statuses`).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(this.handleError)
    );
  };

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }
}

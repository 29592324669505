import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class KeyAndSecretInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/enquiry') || request.url.includes('/subscription')) {
      request = request.clone({
        setHeaders: {
          key: "abcd-efgh-ijkl-mnop",
          secret: "123-456-789-0"
        }
      });
    }
    return next.handle(request);
  }
}

import * as tourActions from './tour.actions';
import * as moment from 'moment';

import { ITourAddon, ITourBooking, ITourDiscount, ITourItinerary, ITourPassenger, ITourPriceCategory, ITour } from 'src/app/core/models/Tour';

export function tourReducer(state: any, action: tourActions.TourAction): any {
  switch (action.type) {
    case tourActions.TourActions.LOAD_TOURS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourActions.LOAD_TOURS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        tours: action.payload,
        message: null
      };
    }
    case tourActions.TourActions.LOAD_TOURS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        tours: null,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourActions.SET_PAGINATION: {
      return {
        ...state,
        isProcessing: false,
        pagination: action.payload
      };
    }
    case tourActions.TourActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case tourActions.TourActions.SET_TOUR: {
      if (action.payload) {
        action.payload.tour_start_date = moment(action.payload.tour_start_date).format('YYYY-MM-DD');
        action.payload.tour_end_date = moment(action.payload.tour_end_date).format('YYYY-MM-DD');
      }
      return {
        ...state,
        isProcessing: false,
        tour: action.payload,
        message: null
      };
    }
    case tourActions.TourActions.SET_TOURS: {
      return {
        ...state,
        isProcessing: false,
        tours: action.payload,
        message: null
      };
    }
    case tourActions.TourActions.LOAD_MORE_TOURS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourActions.LOAD_MORE_TOURS_SUCCESS: {
      const tours = state.tours;
      tours.data = state.tours.data.concat(action.payload.data);
      return {
        ...state,
        isProcessing: false,
        tours: tours,
        message: null
      };
    }
    case tourActions.TourActions.LOAD_MORE_TOURS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourActions.LOAD_TOUR_BY_ID: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case tourActions.TourActions.LOAD_TOUR_BY_ID_SUCCESS: {
      action.payload.tour_start_date = moment(action.payload.tour_start_date).format('YYYY-MM-DD');
      action.payload.tour_end_date = moment(action.payload.tour_end_date).format('YYYY-MM-DD');
      return {
        ...state,
        discount: null,
        discounts: null,
        addon: null,
        addons: null,
        itinerary: null,
        itineraries: null,
        isProcessing: false,
        tour: action.payload,
        message: null
      };
    }
    case tourActions.TourActions.LOAD_TOUR_BY_ID_FAILED: {
      return {
        ...state,
        isProcessing: false,
        tour: null,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourActions.CREATE_TOUR: {
      return {
        ...state,
        isProcessing: true,
        tour: null,
        message: null
      };
    }
    case tourActions.TourActions.CREATE_TOUR_SUCCESS: {
      if (state.tours && state.tours.data && state.tours.data.length) {
        state.tours.data.push(action.payload);
      } else {
        state.tours.data = [];
        state.tours.data.push(action.payload);
      }
      return {
        ...state,
        isProcessing: false,
        tour: null,
        message: { status: 201, info: 'Tour created successfully' }
      };
    }
    case tourActions.TourActions.CREATE_TOUR_FAILED: {
      return {
        ...state,
        isProcessing: false,
        tour: null,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourActions.UPDATE_TOUR: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case tourActions.TourActions.UPDATE_TOUR_SUCCESS: {
      if (state.tours && state.tours.data && state.tours.data.length) {
        state.tours.data[state.tours.data.findIndex((tour: ITour) => tour.id === action.payload.id)] = action.payload;
      }
      return {
        ...state,
        isProcessing: false,
        tour: action.payload,
        message: { status: 200, info: 'Tour updated successfully' }
      };
    }
    case tourActions.TourActions.UPDATE_TOUR_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_DISCOUNTS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        discounts: action.payload
      };
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_DISCOUNTS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourDiscountActions.SET_TOUR_DISCOUNT: {
      return {
        ...state,
        isProcessing: false,
        discount: action.payload
      };
    }
    case tourActions.TourDiscountActions.SET_TOUR_DISCOUNTS: {
      return {
        ...state,
        isProcessing: false,
        discounts: action.payload
      };
    }
    case tourActions.TourDiscountActions.CREATE_TOUR_DISCOUNT: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourDiscountActions.CREATE_TOUR_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        discounts: [...state.discounts, ...action.payload],
        offers: null,
        message: { status: 201, info: 'Tour discount created successfully' }
      };
    }
    case tourActions.TourDiscountActions.CREATE_TOUR_DISCOUNT_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourDiscountActions.UPDATE_TOUR_DISCOUNT: {
      return {
        ...state,
        isProcessing: true,
        discount: null
      };
    }
    case tourActions.TourDiscountActions.UPDATE_TOUR_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        discounts: [
          ...state.discounts.filter((data: ITourDiscount) => data.id !== action.payload.id),
          ...action.payload
        ],
        offers: null,
        message: { status: 200, info: 'Tour discount updated successfully' }
      };
    }
    case tourActions.TourDiscountActions.UPDATE_TOUR_DISCOUNT_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourDiscountActions.REMOVE_TOUR_DISCOUNT: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourDiscountActions.REMOVE_TOUR_DISCOUNT_SUCCESS: {
      if (state.discounts && state.discounts.length && action.payload) {
        for (let index = 0; index < state.discounts.length; index++) {
          if (state.discounts[index].id !== action.payload) {
            state.discounts[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        discounts: [...state.discounts.filter((data: ITourDiscount) => data.id !== action.payload)],
        offers: null,
        message: { status: 200, info: 'Tour discount removed successfully' }
      };
    }
    case tourActions.TourDiscountActions.REMOVE_TOUR_DISCOUNT_FAILED: {
      if (state.discounts && state.discounts.length && action['discountId']) {
        for (let index = 0; index < state.discounts.length; index++) {
          if (state.discounts[index].id !== action['discountId']) {
            state.discounts[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_OFFERS: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_OFFERS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        discounts: null,
        offers: action.payload
      };
    }
    case tourActions.TourDiscountActions.LOAD_TOUR_OFFERS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonActions.LOAD_TOUR_ADDONS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourAddonActions.LOAD_TOUR_ADDONS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        addons: action.payload
      };
    }
    case tourActions.TourAddonActions.LOAD_TOUR_ADDONS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonActions.SET_TOUR_ADDON: {
      return {
        ...state,
        isProcessing: false,
        addon: action.payload
      };
    }
    case tourActions.TourAddonActions.SET_TOUR_ADDONS: {
      return {
        ...state,
        isProcessing: false,
        addons: action.payload
      };
    }
    case tourActions.TourAddonActions.CREATE_TOUR_ADDON: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourAddonActions.CREATE_TOUR_ADDON_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        addons: [...state.addons, ...action.payload],
        message: { status: 201, info: 'Tour add-on created successfully' }
      };
    }
    case tourActions.TourAddonActions.CREATE_TOUR_ADDON_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonActions.UPDATE_TOUR_ADDON: {
      return {
        ...state,
        isProcessing: true,
        addon: null
      };
    }
    case tourActions.TourAddonActions.UPDATE_TOUR_ADDON_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        addons: [
          ...state.addons.filter((data: ITourAddon) => data.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Tour add-on updated successfully' }
      };
    }
    case tourActions.TourAddonActions.UPDATE_TOUR_ADDON_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonActions.REMOVE_TOUR_ADDON: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourAddonActions.REMOVE_TOUR_ADDON_SUCCESS: {
      if (state.addons && state.addons.length && action.payload) {
        for (let index = 0; index < state.addons.length; index++) {
          if (state.addons[index].id !== action.payload) {
            state.addons[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        addons: [...state.addons.filter((data: ITourAddon) => data.id !== action.payload)],
        message: { status: 200, info: 'Tour add-on removed successfully' }
      };
    }
    case tourActions.TourAddonActions.REMOVE_TOUR_ADDON_FAILED: {
      if (state.addons && state.addons.length && action['addonId']) {
        for (let index = 0; index < state.addons.length; index++) {
          if (state.addons[index].id !== action['addonId']) {
            state.addons[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        priceCategories: action.payload
      };
    }
    case tourActions.TourPriceCategoryActions.LOAD_TOUR_PRICE_CATEGORIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPriceCategoryActions.SET_TOUR_PRICE_CATEGORY: {
      return {
        ...state,
        isProcessing: false,
        priceCategory: action.payload
      };
    }
    case tourActions.TourPriceCategoryActions.SET_TOUR_PRICE_CATEGORIES: {
      return {
        ...state,
        isProcessing: false,
        priceCategories: action.payload
      };
    }
    case tourActions.TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        priceCategories: [...state.priceCategories, ...action.payload],
        addonsForPriceCategory: null,
        message: { status: 201, info: 'Tour category created successfully' }
      };
    }
    case tourActions.TourPriceCategoryActions.CREATE_TOUR_PRICE_CATEGORY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY: {
      return {
        ...state,
        isProcessing: true,
        priceCategory: null,
        addonsForPriceCategory: null
      };
    }
    case tourActions.TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        priceCategories: [
          ...state.priceCategories.filter((data: ITourPriceCategory) => data.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Tour category updated successfully' }
      };
    }
    case tourActions.TourPriceCategoryActions.UPDATE_TOUR_PRICE_CATEGORY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY_SUCCESS: {
      if (state.priceCategories && state.priceCategories.length && action.payload) {
        for (let index = 0; index < state.priceCategories.length; index++) {
          if (state.priceCategories[index].id !== action.payload) {
            state.priceCategories[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        priceCategories: [...state.priceCategories.filter((data: ITourPriceCategory) => data.id !== action.payload)],
        message: { status: 200, info: 'Tour category removed successfully' }
      };
    }
    case tourActions.TourPriceCategoryActions.REMOVE_TOUR_PRICE_CATEGORY_FAILED: {
      if (state.priceCategories && state.priceCategories.length && action['priceCategoryId']) {
        for (let index = 0; index < state.priceCategories.length; index++) {
          if (state.priceCategories[index].id !== action['priceCategoryId']) {
            state.priceCategories[index].showRmoveConfirmation = false;
          }
        }
      }
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES: {
      return {
        ...state,
        addonsForPriceCategory: { double: [], single: [] },
        message: null
      }
    }
    case tourActions.TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        addonsForPriceCategory: action.payload
      };
    }
    case tourActions.TourAddonPriceCategoryActions.LOAD_TOUR_ADDON_PRICE_CATEGORIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourAddonPriceCategoryActions.SET_TOUR_ADDON_PRICE_CATEGORIES: {
      return {
        ...state,
        isProcessing: false,
        addonsForPriceCategory: action.payload
      };
    }
    case tourActions.TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_TOUR_AND_PRICE_CATEGORY_SUCCESS: {
      if (action.payload && action.payload.data && action.payload.data[0] && action.payload.data[0].price_category_id && state.priceCategories) {
        state.priceCategories.filter(
          (data: ITourPriceCategory) => {
            if (data.id === action.payload.data[0].price_category_id) {
              data.addons = action.payload.data;
            }
          }
        );
      }
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourAddonPriceCategoryActions.LOAD_ADDONS_FOR_PASSENGER_SUCCESS: {
      if (action.payload && action.payload[0] && action.payload[0].traveler_id && state.passengers) {
        state.passengers.filter(
          (data: ITourPassenger) => {
            if (data.id === action.payload[0].traveler_id) {
              data.add_ons = action.payload;
            }
          }
        );
      }
      return {
        ...state,
        isProcessing: false
      };
    }
    case tourActions.TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY: {
      return {
        ...state,
        message: null
      };
    }
    case tourActions.TourAddonPriceCategoryActions.INSERT_ADDON_FOR_PRICE_CATEGORY_SUCCESS: {
      if (
        !(action.payload &&
          state.addonsForPriceCategory[action.payload.occupancy_type] &&
          state.addonsForPriceCategory[action.payload.occupancy_type].length)
      ) {
        state.addonsForPriceCategory[action.payload.occupancy_type] = [];
      }
      state.addonsForPriceCategory[action.payload.occupancy_type].push(action.payload)
      return {
        ...state,
        message: { status: 202, info: 'Add-on inserted' }
      };
    }
    case tourActions.TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY: {
      return {
        ...state,
        message: null
      };
    }
    case tourActions.TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY_SUCCESS: {
      if (
        action.payload &&
        state.addonsForPriceCategory[action.payload.occupancy_type] &&
        state.addonsForPriceCategory[action.payload.occupancy_type].length
      ) {
        state.addonsForPriceCategory[action.payload.occupancy_type].splice(
          state.addonsForPriceCategory[action.payload.occupancy_type].findIndex(
            addon => addon.add_on_id === action.payload.add_on_id && addon.occupancy_type === action.payload.occupancy_type
          ), 1
        );
      }
      return {
        ...state,
        message: { status: 202, info: 'Add-on deleted' }
      };
    }
    case tourActions.TourAddonPriceCategoryActions.DELETE_ADDON_FOR_PRICE_CATEGORY_FAILED: {
      return {
        ...state,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourItineraryActions.LOAD_TOUR_ITINERARIES: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourItineraryActions.LOAD_TOUR_ITINERARIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        itineraries: action.payload
      };
    }
    case tourActions.TourItineraryActions.LOAD_TOUR_ITINERARIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourItineraryActions.SET_TOUR_ITINERARY: {
      return {
        ...state,
        isProcessing: false,
        itinerary: action.payload
      };
    }
    case tourActions.TourItineraryActions.SET_TOUR_ITINERARIES: {
      return {
        ...state,
        isProcessing: false,
        itineraries: action.payload
      };
    }
    case tourActions.TourItineraryActions.CREATE_TOUR_ITINERARY: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourItineraryActions.CREATE_TOUR_ITINERARY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        itineraries: [...state.itineraries, ...action.payload],
        message: { status: 201, info: 'Tour itinerary created successfully' }
      };
    }
    case tourActions.TourItineraryActions.CREATE_TOUR_ITINERARY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourItineraryActions.UPDATE_TOUR_ITINERARY: {
      return {
        ...state,
        isProcessing: true,
        itinerary: null
      };
    }
    case tourActions.TourItineraryActions.UPDATE_TOUR_ITINERARY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        itineraries: [
          ...state.itineraries.filter((data: ITourItinerary) => data.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Tour itinerary updated successfully' }
      };
    }
    case tourActions.TourItineraryActions.UPDATE_TOUR_ITINERARY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourItineraryActions.REMOVE_TOUR_ITINERARY: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourItineraryActions.REMOVE_TOUR_ITINERARY_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        itineraries: [...state.itineraries.filter((data: ITourItinerary) => data.id !== action.payload)],
        message: { status: 200, info: 'Tour itinerary removed successfully' }
      };
    }
    case tourActions.TourItineraryActions.REMOVE_TOUR_ITINERARY_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.LOAD_BOOKING_STATUSES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_BOOKING_STATUSES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        bookingStatuses: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_BOOKING_STATUSES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.LOAD_PAYMENT_STATUSES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_PAYMENT_STATUSES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        paymentStatuses: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_PAYMENT_STATUSES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKINGS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKINGS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        bookings: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKINGS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.SET_TOUR_BOOKING: {
      return {
        ...state,
        isProcessing: false,
        booking: action.payload
      };
    }
    case tourActions.TourBookingActions.SET_TOUR_BOOKINGS: {
      return {
        ...state,
        isProcessing: false,
        bookings: action.payload
      };
    }
    case tourActions.TourBookingActions.SET_BOOKING_FOR_PAYMENT: {
      return {
        ...state,
        isProcessing: false,
        selectedBookingForPayment: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        booking: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.CREATE_TOUR_BOOKING: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourBookingActions.CREATE_TOUR_BOOKING_SUCCESS: {
      state.passengers = [];
      if (action.payload && action.payload.is_passenger && action.payload.traveler_id) {
        state.passengers.push({
          id: action.payload.traveler_id,
          company_id: action.payload.company_id,
          booking_id: action.payload.id,
          last_name: action.payload.booked_by_last_name,
          first_name: action.payload.booked_by_first_name,
          middle_name: action.payload.booked_by_middle_name,
          date_of_birth: action.payload.date_of_birth,
          email_id: action.payload.email_id,
          phone_number: action.payload.phone_number,
          passport: null,
          passport_issue_date: null,
          passport_expiry_date: null,
          passport_issuer: null,
          badge_name: null,
          visa_required_flag: false,
          traveler_doc_flag: false,
          traveler_doc_sent_flag: false,
          address: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          postal_code_or_zip: action.payload.postal_code_or_zip,
          country: action.payload.country,
          price_category_id: action.payload.price_category_id,
          price_category_name: action.payload.price_category_name,
          price_category_type: action.payload.price_category_type,
          category_price: action.payload.category_price,
          saving_id: action.payload.saving_id,
          saving_name: action.payload.saving_name,
          saving_value: action.payload.saving_value,
          price_after_saving: action.payload.price_after_saving,
          add_ons: action.payload.add_ons,
          showPassengerDetails: true,
          showPersonalDetails: true,
          showPassportDetails: false,
          showPriceDetails: false
        })
      }
      return {
        ...state,
        isProcessing: false,
        booking: action.payload,
        bookings: [...state.bookings, ...action.payload],
        message: { status: 201, info: 'Tour booking created successfully' }
      };
    }
    case tourActions.TourBookingActions.CREATE_TOUR_BOOKING_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.UPDATE_TOUR_BOOKING: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourBookingActions.UPDATE_TOUR_BOOKING_SUCCESS: {
      if (state.bookings) {
        state.bookings[state.bookings.findIndex((booking: ITourBooking) => booking.id === action.payload.id)] = action.payload;
      }
      if (state.booking) { state.booking = action.payload }
      if (state.selectedBookingForPayment) { state.selectedBookingForPayment = action.payload }
      return {
        ...state,
        isProcessing: false,
        message: { status: 200, info: 'Tour booking updated successfully' }
      };
    }
    case tourActions.TourBookingActions.UPDATE_TOUR_BOOKING_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.REMOVE_TOUR_BOOKING: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourBookingActions.REMOVE_TOUR_BOOKING_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        bookings: [...state.bookings.filter((data: ITourBooking) => data.id !== action.payload)],
        message: { status: 200, info: 'Tour booking removed successfully' }
      };
    }
    case tourActions.TourBookingActions.REMOVE_TOUR_BOOKING_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE: {
      return {
        ...state,
        isProcessing: false
      }
    }
    case tourActions.TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE_SUCCESS: {
      if (state.booking && action.payload) {
        state.booking.total_traveler_fee = action.payload.total_traveler_fee;
        state.booking.total_traveler_fee_after_saving = action.payload.total_traveler_fee_after_saving;
      }
      if (state.booking && state.bookings && action.payload) {
        let index = state.bookings.findIndex((booking: ITourBooking) => booking.id === state.booking.id);
        state.bookings[index].total_traveler_fee = action.payload.total_traveler_fee;
        state.bookings[index].total_traveler_fee_after_saving = action.payload.total_traveler_fee_after_saving;
      }
      return {
        ...state,
        isProcessing: false
      };
    }
    case tourActions.TourBookingActions.FETCH_TOUR_BOOKING_TOTAL_FEE_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        paymentModes: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_MODES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING: {
      return {
        ...state,
        isProcessing: false,
        message: null
      }
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        paymentHistory: action.payload
      };
    }
    case tourActions.TourBookingActions.LOAD_TOUR_BOOKING_PAYMENT_HISTORY_BY_BOOKING_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourBookingActions.CREATE_PAYMENT: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourBookingActions.CREATE_PAYMENT_SUCCESS: {
      state.selectedBookingForPayment.total_payments_made += action.payload.amount;
      return {
        ...state,
        isProcessing: false,
        paymentHistory: [...state.paymentHistory, ...action.payload],
        message: { status: 201, info: 'Paid successfully' }
      };
    }
    case tourActions.TourBookingActions.CREATE_PAYMENT_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPassengerActions.LOAD_TOUR_PASSENGERS: {
      return {
        ...state,
        isProcessing: true,
        message: null
      }
    }
    case tourActions.TourPassengerActions.LOAD_TOUR_PASSENGERS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        passengers: action.payload
      };
    }
    case tourActions.TourPassengerActions.LOAD_TOUR_PASSENGERS_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPassengerActions.SET_TOUR_PASSENGER: {
      return {
        ...state,
        isProcessing: false,
        passenger: action.payload
      };
    }
    case tourActions.TourPassengerActions.SET_TOUR_PASSENGERS: {
      return {
        ...state,
        isProcessing: false,
        passengers: action.payload
      };
    }
    case tourActions.TourPassengerActions.CREATE_TOUR_PASSENGER: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourPassengerActions.CREATE_TOUR_PASSENGER_SUCCESS: {
      if (state.booking && state.bookings && action.payload) {
        let index = state.bookings.findIndex((booking: ITourBooking) => booking.id === state.booking.id);
        state.bookings[index].passenger_count++;
      }
      return {
        ...state,
        isProcessing: false,
        passengers: [...state.passengers || [], ...action.payload],
        message: {
          status: 201,
          info: 'Tour passenger created successfully'
        }
      };
    }
    case tourActions.TourPassengerActions.CREATE_TOUR_PASSENGER_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPassengerActions.UPDATE_TOUR_PASSENGER: {
      return {
        ...state,
        isProcessing: true,
        passenger: null
      };
    }
    case tourActions.TourPassengerActions.UPDATE_TOUR_PASSENGER_SUCCESS: {
      if (action.payload && state.booking && state.booking.traveler_id === action.payload.id) {
        state.booking.booked_by_last_name = action.payload.last_name;
        state.booking.booked_by_first_name = action.payload.first_name;
        state.booking.booked_by_middle_name = action.payload.middle_name;
        state.booking.date_of_birth = action.payload.date_of_birth;
        state.booking.email_id = action.payload.email_id;
        state.booking.phone_number = action.payload.phone_number;
        state.booking.address = action.payload.address;
        state.booking.city = action.payload.city;
        state.booking.state = action.payload.state;
        state.booking.postal_code_or_zip = action.payload.postal_code_or_zip;
        state.booking.country = action.payload.country;
      }
      if (state.booking && state.bookings && action.payload) {
        let index = state.bookings.findIndex((booking: ITourBooking) => booking.id === state.booking.id);
        state.bookings[index].booked_by_last_name = action.payload.last_name;
        state.bookings[index].booked_by_first_name = action.payload.first_name;
        state.bookings[index].booked_by_middle_name = action.payload.middle_name;
        state.bookings[index].date_of_birth = action.payload.date_of_birth;
        state.bookings[index].email_id = action.payload.email_id;
        state.bookings[index].phone_number = action.payload.phone_number;
        state.bookings[index].address = action.payload.address;
        state.bookings[index].city = action.payload.city;
        state.bookings[index].state = action.payload.state;
        state.bookings[index].postal_code_or_zip = action.payload.postal_code_or_zip;
        state.bookings[index].country = action.payload.country;
      }
      return {
        ...state,
        isProcessing: false,
        passengers: [
          ...state.passengers.filter((data: ITourPassenger) => data.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'Tour passenger updated successfully' }
      };
    }
    case tourActions.TourPassengerActions.UPDATE_TOUR_PASSENGER_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case tourActions.TourPassengerActions.REMOVE_TOUR_PASSENGER: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case tourActions.TourPassengerActions.REMOVE_TOUR_PASSENGER_SUCCESS: {
      if (state.booking) {
        state.booking.passenger_count--;
      }
      if (state.booking && state.bookings && action.payload && action.payload.passengerId) {
        let index = state.bookings.findIndex((booking: ITourBooking) => booking.id === state.booking.id);
        state.bookings[index].passenger_count--;
      }
      return {
        ...state,
        isProcessing: false,
        passengers: [...state.passengers.filter((data: ITourPassenger) => data.id !== action.payload.passengerId)],
        message: { status: 200, info: 'Tour passenger removed successfully' }
      };
    }
    case tourActions.TourPassengerActions.REMOVE_TOUR_PASSENGER_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    default: {
      return state;
    }
  }
}

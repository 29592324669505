import * as enquiryActions from './enquiry.actions';

export function enquiryReducer(state: any, action: enquiryActions.EnquiryAction): any {
  switch (action.type) {
    case enquiryActions.EnquiryActions.LOAD_ENQUIRIES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case enquiryActions.EnquiryActions.LOAD_ENQUIRIES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        enquiries: action.payload,
        message: null
      };
    }
    case enquiryActions.EnquiryActions.LOAD_ENQUIRIES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        enquiries: null,
        message: { status: 412, error: action.payload }.error || action.payload
      };
    }
    default: {
      return state;
    }
  }
}

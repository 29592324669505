import * as geographicCodeActions from './geographic.code.actions';
import { IGeographicCode } from 'src/app/core/models/GeographicCode';

export function geographicCodeReducer(state: any, action: geographicCodeActions.GeographicCodeAction): any {
  switch (action.type) {
    case geographicCodeActions.GeographicCodeActions.LOAD_GEOGRAPHICCODES: {
      return {
        ...state,
        isProcessing: false,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.LOAD_GEOGRAPHICCODES_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        geographicCodes: action.payload,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.LOAD_GEOGRAPHICCODES_FAILED: {
      return {
        ...state,
        isProcessing: false,
        geographicCodes: null,
        message: { status: 412, error: action.payload }
      };
    }
    case geographicCodeActions.GeographicCodeActions.SET_GEOGRAPHICCODE: {
      return {
        ...state,
        isProcessing: false,
        geographicCode: action.payload,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.SET_GEOGRAPHICCODES: {
      return {
        ...state,
        isProcessing: false,
        geographicCodes: action.payload,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.SET_MESSAGE: {
      return {
        ...state,
        isProcessing: false,
        message: action.payload
      };
    }
    case geographicCodeActions.GeographicCodeActions.CREATE_GEOGRAPHICCODE: {
      return {
        ...state,
        isProcessing: true,
        geographicCode: null,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.CREATE_GEOGRAPHICCODE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        geographicCodes: [...state.geographicCodes, ...action.payload],
        message: { status: 201, info: 'GeographicCode created successfully' }
      };
    }
    case geographicCodeActions.GeographicCodeActions.CREATE_GEOGRAPHICCODE_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case geographicCodeActions.GeographicCodeActions.UPDATE_GEOGRAPHICCODE: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.UPDATE_GEOGRAPHICCODE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        geographicCode: null,
        geographicCodes: [
          ...state.geographicCodes.filter((geographicCode: IGeographicCode) => geographicCode.id !== action.payload.id),
          ...action.payload
        ],
        message: { status: 200, info: 'GeographicCode updated successfully' }
      };
    }
    case geographicCodeActions.GeographicCodeActions.UPDATE_GEOGRAPHICCODE_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    case geographicCodeActions.GeographicCodeActions.REMOVE_GEOGRAPHICCODE: {
      return {
        ...state,
        isProcessing: true,
        message: null
      };
    }
    case geographicCodeActions.GeographicCodeActions.REMOVE_GEOGRAPHICCODE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        geographicCode: null,
        geographicCodes: state.geographicCodes.filter((geographicCode: IGeographicCode) => geographicCode.id !== action.payload),
        message: { status: 200, info: 'GeographicCode removed successfully' }
      };
    }
    case geographicCodeActions.GeographicCodeActions.REMOVE_GEOGRAPHICCODE_FAILED: {
      return {
        ...state,
        isProcessing: false,
        message: { status: 412, error: action.payload }
      };
    }
    default: {
      return state;
    }
  }
}
